import React, { useContext, useState } from "react"
import { redirect, useNavigate } from "react-router-dom"
import { FiArrowRight, FiX } from "react-icons/fi"
import "./style.css"
import MaskCPF from "../../../inc/Mask/cpf"
import MaskPhone from "../../../inc/Mask/telefone"
import updateLead from "../../../inc/Fx/updateLead"
import api from "../../../services/api"
import encryptClass from "../../../inc/Controllers/encrypt.class"
import { GlobalContext } from "../../../contexts/global"
import validateCpf from "../../../inc/Fx/validateCpf"
import Alert from "../../Global/Alert"

const Popup = props => {
  const [client_cpf, set_client_cpf] = useState(
    sessionStorage.getItem("client_cpf") !== null
      ? sessionStorage.getItem("client_cpf")
      : ""
  )
  const [client_phone, set_client_phone] = useState(
    sessionStorage.getItem("client_phone") !== null
      ? sessionStorage.getItem("client_phone")
      : ""
  )

  const [cpfError, setCpfError] = useState ("")
  const [isAlertOpen, setIsAlertOpen] = useState(false)

  let popupClassName = props.isOpen
    ? "site-popup-cpf popup-open"
    : "site-popup-cpf"

  const navigate = useNavigate()

  const close_popup = e => {
    e.preventDefault()
    props.toggle(false)
  }

  const { setPatientFound, setStatusClubflex, setLoaderState } = useContext(
    GlobalContext
  )

  const handleSubmit = async e => {
    e.preventDefault()
    setLoaderState(true)
    if(!validateCpf(client_cpf)){
      setCpfError("CPF inválido.")
      setIsAlertOpen(true)
      setLoaderState(false)
      return
    }

    updateLead({
      CLIENT_CPF: client_cpf,
      CLIENT_PHONE: client_phone,
      FEEGOW_TIPO_AGENDAMENTO: "consultas-medicas"
    })

    const escClientCpf = client_cpf.replace(/\D/g, "")

    // Fetch Patient
    await api
      .post("/patient", {
        client_cpf: escClientCpf
      })
      .then(feegowResp => {
        const { success, content } = feegowResp.data
        if (success) {
          let patientFound = encryptClass.hash(
            JSON.stringify({
              id: content.id,
              nome: content.nome,
              email: content.email[0],
              nasc: content.nascimento,
              sexo: content.sexo
            })
          )

          sessionStorage.removeItem("client_name")
          sessionStorage.removeItem("client_email")
          sessionStorage.removeItem("client_nascimento")
          sessionStorage.removeItem("client_genre")
          sessionStorage.removeItem("patient")

          updateLead({
            FEEGOW_PATIENT_ID: content.id
          })
          setPatientFound(patientFound)
          sessionStorage.setItem("typeTabelaId", content.tabela_id)
          sessionStorage.setItem("patientFound", patientFound)
        } else {
          setPatientFound(null)
          updateLead({
            FEEGOW_PATIENT_ID: null
          })
          sessionStorage.removeItem("patientFound")
        }
      })

    // Detect clubflex holding status
    const cfUrl = `/clubflex/${escClientCpf}`

    await api.get(cfUrl).then(cfResp => {
      const { success, content } = cfResp.data
      setLoaderState(false)

      if (success) {
        let statusClubflex = encryptClass.hash(
          JSON.stringify({
            isClubFlex: true,
            isClubFlexActive: content.status === "OK"
          })
        )
        sessionStorage.setItem("statusClubflex", statusClubflex)
        
        if(content.type === "PJ") {
          sessionStorage.setItem("statusClubflex", "PJ")
          sessionStorage.setItem("nome_Empresa", content.name)
        }
        if(content.status === "BLOCKED"){
          sessionStorage.setItem("statusClubflex", "BLOCKED")
        }

        sessionStorage.removeItem("convenio")
        setStatusClubflex(statusClubflex)
        updateLead({
          CLIENT_IS_CLUBFLEX: true,
          CLIENT_STATUS: content.status
          
        })

        navigate("/cadastro")
      } else {
        sessionStorage.removeItem("statusClubflex")
        sessionStorage.removeItem("nome_Empresa")

        setStatusClubflex(null)

        updateLead({
          CLIENT_IS_CLUBFLEX: false
        })

        navigate("/convenio")
      }
    })
  }

  return (
    <section className={popupClassName}>
      <div className="glass" onClick={close_popup}></div>
      <div className="content">
        <span className="close-button" onClick={close_popup}>
          <FiX size={44} />
        </span>
        <h2 className="popup-title">
          Por favor, <br /> informe os dados do <strong>paciente a ser atendido</strong>:
        </h2>
        <form onSubmit={handleSubmit} className="form-popup">
          <div className="popup-form-content">
            <div className="input-text" style={{ marginBottom: 40 }}>
              <label htmlFor="client_cpf">
                <p>CPF</p>
                <input
                  type="text"
                  inputMode="numeric"
                  name="client_cpf"
                  id="client_cpf"
                  autoComplete="off"
                  value={client_cpf}
                  placeholder="Digite seu CPF"
                  maxLength={14}
                  required
                  onBlur={sessionStorage.setItem("client_cpf", client_cpf)}
                  onChange={e => set_client_cpf(MaskCPF(e.target.value))}
                />
              </label>
            </div>
            <div className="input-text">
              <label htmlFor="client_phone">
                <p>Celular</p>
                <input
                  type="text"
                  inputMode="numeric"
                  name="client_phone"
                  id="client_phone"
                  value={client_phone}
                  placeholder="DDD + Celular"
                  maxLength={15}
                  onBlur={sessionStorage.setItem("client_phone", client_phone)}
                  required
                  onChange={e => set_client_phone(MaskPhone(e.target.value))}
                />
              </label>
            </div>
          </div>

          <div className="input-submit lightblue-submit">
            <button type="submit">
              Prosseguir
              <FiArrowRight />
            </button>
          </div>
        </form>
      </div>
      <Alert isOpen={isAlertOpen} onClose={setIsAlertOpen} title="Atenção!" >
        <p>{cpfError}</p>
      </Alert>
    </section>
  )
}

export default Popup
