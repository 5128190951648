import api from "../../services/api"
import leadDefaultHeader from "../../services/api_leadDefaultHeader"

const updateLead = data => {
  if (leadDefaultHeader()) {
    api.patch("/lead", data, leadDefaultHeader())
  }
}

export default updateLead
