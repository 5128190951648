import React, { useContext } from "react"
import Header from "../../Global/Header"
import PageContent from "../../Global/PageContent"
import maskEmail from "../../../inc/Mask/email"
import { FiArrowRight } from "react-icons/fi"
import { Link, useNavigate } from "react-router-dom"
import updateLead from "../../../inc/Fx/updateLead"
import convertBRDateToIso8601 from "../../../inc/Fx/convertBRDateToIso8601"
import { GlobalContext } from "../../../contexts/global"
import encryptClass from "../../../inc/Controllers/encrypt.class"

export default function CadastroEncontrado() {
  const navigate = useNavigate()

  const {
    patientFound,
    statusClubflex,
    setStatusClubflex,
    setPatientFound
  } = useContext(GlobalContext)

  let { nome, email, nasc } = JSON.parse(encryptClass.reveal(patientFound))

  const handleReset = () => {
    sessionStorage.removeItem("patientFound")
    sessionStorage.removeItem("statusClubflex")
    sessionStorage.removeItem("client_cpf")
    sessionStorage.removeItem("client_phone")
    sessionStorage.removeItem("convenio")
    setStatusClubflex(false)
    setPatientFound(false)
  }

  const handleSubmit = event => {
    event.preventDefault()

    updateLead({
      CLIENT_NAME: nome,
      CLIENT_EMAIL: email,
      CLIENT_NASCIMENTO: convertBRDateToIso8601(nasc)
    })

    navigate("/especialidade")
  }

  const nomeEmpresa = sessionStorage.getItem("nome_Empresa");
  const clubflexExist = sessionStorage.getItem("statusClubflex")

  function ttitleCase(nomeEmpresa) {
    return nomeEmpresa.charAt(0).toUpperCase() + nomeEmpresa.slice(1).toLowerCase();
  }

  let clubflexType =
    <> Paciente {statusClubflex ? "Clubflex" : ""}  </>


  if ( clubflexExist === "PJ") {
   clubflexType = <> Paciente Clubflex ({ttitleCase(nomeEmpresa)}) </>
  }

  const mainClassName = statusClubflex ? "page-clubflex" : ""

  return (
    <main className={`site-main page-cadastro ${mainClassName}`}>
      <Header back_to={statusClubflex ? "/" : "/convenio"} hideFollowUP={true}>
        <h1> {statusClubflex ? clubflexType : "Paciente"}</h1>
      </Header>
      <PageContent>
        <h3 >Bem-vindo(a)<br/> de volta, <span style={{ color: "#8eb3be" }}>{nome.split(" ")[0]}</span></h3>
        <p style={{ marginBottom: "1rem" }}>
          Confira seus dados abaixo antes de <br/> prosseguir com o agendamento.
        </p>
        <div className="white-card" style={{ marginBottom: "1rem" }}>
          {email ? <p>E-mail: {maskEmail(email)}</p> : <></>}
          {/* eslint-disable-next-line */}
          <p>Data de nascimento: {nasc.replace(/\-/g, "/")}</p>
        </div>
        <p>
          Não é você?
          <Link to={"/"} onClick={handleReset}>
            Informar outro CPF
          </Link>
        </p>
        <form onSubmit={handleSubmit}>
          <div className="d-flex justify-content-center">
            <div className="input-submit">
              <button type="submit">
                Continuar
                <FiArrowRight />
              </button>
            </div>
          </div>
        </form>
      </PageContent>
    </main>
  )
}
