import React from "react"
import "./style.css"
import LogoClubFlex from "../../../assets/BannerClubFlex.png"

export default function Paciente() {
  return (
    <div className="pacient-data">
      <div id="patient-disclaimer">
        <p>
          - Você será cobrado somente no dia da consulta; <br />- Chegar na
          unidade com 30 minutos de antecedência. <br />- Não esqueça de trazer seus 
          documentos de identificação com foto.
        </p>
      </div>

      <div className="clubflex">
        <figure>
          <a
            href="https://api.whatsapp.com/send?phone=2126665800"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={LogoClubFlex} alt="ClubFlex" />
          </a>
        </figure>
      </div>
    </div>
  )
}
