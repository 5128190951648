export default function dateISO8601(showTime = false, addDate = 0) {
  const dateObject = new Date()

  if (addDate > 0) {
    dateObject.setDate(dateObject.getDate() + addDate)
  }

  // Ano
  let dateStamp = `${dateObject.getFullYear()}-`

  // Mês
  dateStamp +=
    dateObject.getMonth() + 1 < 10
      ? `0${dateObject.getMonth() + 1}`
      : dateObject.getMonth() + 1

  // dia
  dateStamp +=
    dateObject.getDate() < 10
      ? `-0${dateObject.getDate()}T`
      : `-${dateObject.getDate()}T`

  if (showTime) {
    // Hora
    dateStamp +=
      dateObject.getHours() < 10
        ? `0${dateObject.getHours()}:`
        : `${dateObject.getHours()}:`

    // Minutos
    dateStamp +=
      dateObject.getMinutes() < 10
        ? `0${dateObject.getMinutes()}:`
        : `${dateObject.getMinutes()}:`

    // Segundos
    dateStamp +=
      dateObject.getSeconds() < 10
        ? `0${dateObject.getSeconds()}Z`
        : `${dateObject.getSeconds()}Z`
  }

  return dateStamp
}
