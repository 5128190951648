import React, { useContext, useState } from "react"
import { redirect, useNavigate } from "react-router-dom"
import { FiArrowRight, FiChevronDown } from "react-icons/fi"
import Header from "../Global/Header"
import PageContent from "../Global/PageContent"
import MaskDate from "../../inc/Mask/date"
import updateLead from "../../inc/Fx/updateLead"
import api from "../../services/api"
import axios from "axios"
import Popup from "../Global/Popup"
import { GlobalContext } from "../../contexts/global"
import Alert from "../Global/Alert"

export default function Cadastro() {
  const navigate = useNavigate()
  const { setLoaderState } = useContext(GlobalContext)

  const [client_name, set_client_name] = useState(
    sessionStorage.getItem("client_name") !== null
      ? sessionStorage.getItem("client_name")
      : "" )
  const [client_email, set_client_email] = useState(
    sessionStorage.getItem("client_email") !== null
      ? sessionStorage.getItem("client_email")
      : "" )
  const [client_nascimento, set_client_nascimento] = useState(
    sessionStorage.getItem("client_nascimento") !== null
      ? sessionStorage.getItem("client_nascimento")
      : "" )

  const [clientGenre, setClientGenre] = useState()
  const [isPopupValidationOpen, setIsPopupValidationOpen] = useState(false)
  const [popupErrorMessage, setPopupErrorMessage] = useState("")
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)

  const getDataNascimentoIso8601 = (string) => {
    const dataNascimento = string.replace(/\D/g, "")
    let dia = dataNascimento.substring(0, 2)
    let mes = dataNascimento.substring(2, 4)
    let ano = dataNascimento.substring(4, 8)
    return `${ano}-${mes}-${dia}`
  }

  const escSqlDataNascimento = (dataISO8601) => {
    return `${getDataNascimentoIso8601(dataISO8601)}T00:00:00.000Z`
  }

  const handleDataNascimentoBlur = () => {
    sessionStorage.setItem("client_nascimento", client_nascimento)
    updateLead({
      CLIENT_NASCIMENTO: escSqlDataNascimento(client_nascimento),
    })
  }

  const handle_submit = (e) => {
    e.preventDefault()
    setLoaderState(true)
    // setIsPopupValidationOpen(true)
    
    api
    .post("/patient-store", {
        clientName: client_name,
        clientEmail: client_email,
        clientBirthDate: escSqlDataNascimento(client_nascimento),
        clientGenre: clientGenre,
        clientPhone: sessionStorage.getItem("client_phone").replace(/\D/g, ""),
        clientCpf: sessionStorage.getItem("client_cpf").replace(/\D/g, ""),
      })
      .then((feegowResp) => {
        sessionStorage.setItem("patient", feegowResp.data.content)
        updateLead({
          FEEGOW_PATIENT_ID: feegowResp.data.content,
        })
        updateLead({
          CLIENT_NAME: client_name,
          CLIENT_EMAIL: client_email,
          CLIENT_NASCIMENTO: escSqlDataNascimento(client_nascimento)})
          setLoaderState(false)
          setShowSuccessAlert(true)
      })
      .catch((error) => {
        setIsPopupValidationOpen(true)
        if (axios.isAxiosError(error)) {
          const { data } = error.response
          const errorMessage = []

          if (data.err === "validate") {
            errorMessage.push(
              <p>
                <b>Foram detectados alguns erros:</b>
              </p>
            )
            data.content.issues.forEach((element) => {
              errorMessage.push(<p> - {element.message}</p>)
            })

            setPopupErrorMessage(errorMessage)
          } else {
            setIsPopupValidationOpen("Não foi possível concluir sua solicitação. Tente novamente mais tarde")
          }
        } else {
          setPopupErrorMessage("Não foi possível concluir sua solicitação. Tente novamente mais tarde")
        }
        setLoaderState(false)
      })
  }

  const handleCloseAlert = () => {
    setShowSuccessAlert(false)
    navigate("/")
  }

  return (
    <main className="site-main page-cadastro">
      <Header back_to="/convenio" hideFollowUP={true}>
        <h1>Cadastre-se</h1>
      </Header>
      <PageContent>
      {showSuccessAlert && (
        <Alert isOpen={showSuccessAlert} onClose={handleCloseAlert} title="Sucesso">
          <p>Cadastro concluído com sucesso.<br/>coloque o CPF novamente</p>
        </Alert>
        )}
        <Popup open={isPopupValidationOpen} handle={setIsPopupValidationOpen}>
          {popupErrorMessage}
        </Popup>
        <p>Complete o seu cadastro e prossiga.</p>
        <form style={{ marginTop: "2rem" }} onSubmit={handle_submit}>
          <div className="input-text" style={{ marginBottom: "2rem" }}>
            <label htmlFor="client_name">
              <p>Nome</p>
              <input
                name="client_name"
                onBlur={() => {
                  updateLead({ CLIENT_NAME: client_name })
                  sessionStorage.setItem("client_name", client_name)
                }}
                value={client_name}
                onChange={(e) => set_client_name(e.target.value)}
                placeholder="Digite o seu nome"
                required
              />
            </label>
          </div>

          <div className="input-text" style={{ marginBottom: "2rem" }}>
            <label htmlFor="client_email">
              <p>E-mail</p>
              <input
                name="client_email"
                onBlur={() => {
                  updateLead({
                    CLIENT_EMAIL: client_email,
                  })
                  sessionStorage.setItem("client_email", client_email)
                }}
                value={client_email}
                onChange={(e) => set_client_email(e.target.value)}
                placeholder="E-mail"
                type="email"
                required
              />
            </label>
          </div>

          <div className="input-text" style={{ marginBottom: "3rem" }}>
            <label htmlFor="client_nascimento">
              <p>Data de nascimento</p>
              <input
                name="client_nascimento"
                onBlur={() => handleDataNascimentoBlur()}
                value={client_nascimento}
                onChange={(e) =>
                  set_client_nascimento(MaskDate(e.target.value))
                }
                placeholder="DD/MM/YYYY"
                maxLength={10}
                required
              />
            </label>
          </div>

          <div className="input-select">
            <label htmlFor="client_genre">
              <select
                name="client_genre"
                id="client_genre"
                onChange={(e) => {
                  setClientGenre(e.target.value)
                  sessionStorage.setItem("client_genre", e.target.value)
                }}
              >
                <option value={0}>Selecione o Sexo</option>
                <option value="M">Masculino</option>
                <option value="F">Feminino</option>
              </select>
              <FiChevronDown />
            </label>
          </div>

          <div className="d-flex justify-content-center">
            <div className="input-submit" style={{ position: "initial" }}>
              <button type="submit">
                Prosseguir
                <FiArrowRight />
              </button>
            </div>
          </div>
        </form>
      </PageContent>
    </main>
  )
}
