import React, { useContext } from "react"
import { Link } from "react-router-dom"
import "./style.css"
import { FiEdit3 } from "react-icons/fi"
import datePtBRLabel from "../../../inc/Fx/datePtBRLabel"
import dateWeekPtBRLabel from "../../../inc/Fx/dateWeekPtBRLabel"
import { GlobalContext } from "../../../contexts/global"

export default function FollowUP({
  children,
  hideLink,
  showUnitAddress,
  showUnitMapLink
}) {
  let especialidade = sessionStorage.getItem("especialidade")
  let unidade = sessionStorage.getItem("unidade")
  let medico = sessionStorage.getItem("medico")
  let data = sessionStorage.getItem("date_selected")
  let horario = sessionStorage.getItem("horario")
  let convenio = sessionStorage.getItem("convenio")

  let mapLink = `https://www.google.com.br/maps/place/`
  if (unidade) {
    let unidadeId = unidade.split("::")[1]
    mapLink += JSON.parse(sessionStorage.getItem("units"))[`unid-${unidadeId}`]
      .endereco
  }

  const { statusClubflex } = useContext(GlobalContext)

  return (
    <div id="follow-up">
      <table>
        <tbody>
          {convenio && !statusClubflex && (
            <tr>
              {convenio !== "true" ? <td>Convênio</td> : ""}
              <td>
                <strong>
                  {convenio === "true" ? "Particular" : convenio.split("::")[0]}
                </strong>
              </td>
            </tr>
          )}
          {statusClubflex && (
            <tr>
              <td>
                <strong>Clubflex</strong>
              </td>
            </tr>
          )}
          {especialidade && (
            <tr>
              <td>Especialidade:</td>
              <td>
                <strong>{especialidade.split("::")[0]}</strong>
                {!hideLink && (
                  <Link to={"/especialidade"}>
                    <FiEdit3 />
                  </Link>
                )}
              </td>
            </tr>
          )}
          {unidade && (
            <tr>
              <td>Unidade:</td>
              <td>
                <div style={{ display: "flex" }}>
                  <p style={{ marginBottom: 0 }}>
                    <strong>{unidade.split("::")[0]}</strong>
                    {showUnitAddress && (
                      <>
                        <br />
                        Aqui o endereço da unidade
                      </>
                    )}
                    {showUnitMapLink && (
                      <>
                        <br />
                        <a
                          href={mapLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ marginLeft: 0 }}
                        >
                          Ver no mapa
                        </a>
                      </>
                    )}
                  </p>
                  {!hideLink && (
                    <Link to={"/unidade"}>
                      <FiEdit3 />
                    </Link>
                  )}
                </div>
              </td>
            </tr>
          )}
          {medico && (
            <tr>
              <td>Médico:</td>
              <td>
                <strong>{medico.split("::")[0]}</strong>
                {!hideLink && (
                  <Link to={"/medico"}>
                    <FiEdit3 />
                  </Link>
                )}
              </td>
            </tr>
          )}
          {data && (
            <tr>
              <td>Data:</td>
              <td>
                <strong>
                  {dateWeekPtBRLabel(new Date(data))} -{" "}
                  {datePtBRLabel(new Date(data))}
                </strong>
                {!hideLink && (
                  <Link to={"/agendamento"}>
                    <FiEdit3 />
                  </Link>
                )}
              </td>
            </tr>
          )}
          {horario && (
            <tr>
              <td>Horário:</td>
              <td>
                <strong>{horario}</strong>
                {!hideLink && (
                  <Link to={"/agendamento"}>
                    <FiEdit3 />
                  </Link>
                )}
              </td>
            </tr>
          )}
          {children}
        </tbody>
      </table>
    </div>
  )
}
