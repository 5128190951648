import cryptoJs from "crypto-js"
import { salt } from "../../cryptoKeys.json"

class encrypt {
  hash(string) {
    return cryptoJs.AES.encrypt(string, salt).toString()
  }

  reveal(string) {
    return cryptoJs.AES.decrypt(string, salt).toString(cryptoJs.enc.Utf8)
  }
}

export default new encrypt()
