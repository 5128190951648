import React from "react"
import "./style.css"
import { useNavigate } from "react-router-dom"

export default function Alert({ isOpen, onClose, children, title, callback, disabled, showOkButton= true }) {
  const navigate = useNavigate()

  function handle(event) {
    event.preventDefault()
    onClose(false)

    if (callback) {
      navigate(callback)
    }
  }

  if (!isOpen) {
    return <></>
  }
  
  return (
    <div className="alert-wrapper">
      <div className="alert-modal">
        <h2>{title}</h2>
        {children}
        {showOkButton && (
          <div className="alert-footer">
            <button onClick={handle} disabled={disabled}>Ok</button>
          </div>
        )}
      </div>
    </div>
  )
}
