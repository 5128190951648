import React, { useEffect, useState, useContext } from "react"
import { useNavigate } from "react-router-dom"
import Header from "../Global/Header"
import PageContent from "../Global/PageContent"
import Timeline from "../Global/Timeline"
import Preco from "./Preco"
import { FiCreditCard } from "react-icons/fi"
import { FaMoneyBillWaveAlt } from "react-icons/fa"
import FollowUP from "../Global/Followup"
import imgTripaBandeiras from "../../assets/logos-cartoes.png"
import IconPix from "../../assets/pix.svg"
import { GlobalContext } from "../../contexts/global"

import "./style.css"
import api from "../../services/api"
import axios from "axios"
import Convenio from "./Convenio"

import updateLead from "../../inc/Fx/updateLead"
import encryptClass from "../../inc/Controllers/encrypt.class"
import datePtBRLabel from "../../inc/Fx/datePtBRLabel"
import convertBRDateToIso8601 from "../../inc/Fx/convertBRDateToIso8601"

export default function Valor() {
  const navigate = useNavigate()

  const { setLoaderState, patientFound, statusClubflex } = useContext(
    GlobalContext
  )
  const [isConvenio, setIsConvenio] = useState()
  const [precoConsulta, setPrecoConsulta] = useState(0)

  const tabelasDoRelatorioDePreco = {
    clubflex: "Clubflex 2022.2",
    particular: "Particular 2022.2",
    clubflexEmpresas: "Clubflex Empresarial 2022.2",
    passaporte: "Passaporte 2022.2"
  }
  // sessionStorage("tipoCliente", tabelasDoRelatorioDePreco)

  useEffect(() => {
    async function handlePrecoConsulta() {
      let isClubFlex =
        statusClubflex !== null
      let typeClubflex = statusClubflex == "PJ" ?
          tabelasDoRelatorioDePreco.clubflexEmpresas :
          tabelasDoRelatorioDePreco.clubflex;
      let isParticular = sessionStorage.getItem("convenio") === "true"
      let isPassaporte = sessionStorage.getItem("typeTabelaId") === "56"
      let tipoCliente = ""

      if(isClubFlex || isParticular ||isPassaporte){
        setIsConvenio(false)
        setLoaderState(true)
      }else {
        setIsConvenio(true)
        setPrecoConsulta(0)
      }
      
      if (isPassaporte) {
        tipoCliente = tabelasDoRelatorioDePreco.passaporte;
      } else if (isClubFlex) {
        tipoCliente = typeClubflex;
      } else {
        tipoCliente = tabelasDoRelatorioDePreco.particular;
      }

      sessionStorage.setItem("tipoCliente", tipoCliente)

        await api
          .post("/preco-procedimento", {

            tableName :
              isPassaporte  ?
                  tabelasDoRelatorioDePreco.passaporte : isClubFlex ?
                  typeClubflex : tabelasDoRelatorioDePreco.particular,
            procedimentoId: parseInt(
              sessionStorage.getItem("especialidade").split("::")[1]
            )

          })
          .then(resp => {

            let { success, content } = resp.data
            if (success) {
              setPrecoConsulta(content)
            } else {
              alert("Não foi possível buscar o preço da consulta")
            }
            setLoaderState(false)
          })
          .catch(error => {
            if (axios.isAxiosError(error)) {
              alert("Não foi possível buscar o preço da consulta")
              setPrecoConsulta(0)
            }
            setLoaderState(false)
          })
      }
    handlePrecoConsulta()
  }, [])

  useEffect(() => {
    if (precoConsulta > 0) {
      updateLead({
        FEEGOW_VALOR_CONSULTA: precoConsulta
      })
    }
  }, [precoConsulta])

  const handle_submit = async e => {
    e.preventDefault()
    setLoaderState(true)

    let profissionalId = sessionStorage.getItem("medico").split("::")[1]
    profissionalId = parseInt(profissionalId)

    let paciente = patientFound
    if (paciente !== null) {
      paciente = encryptClass.reveal(patientFound)
      paciente = JSON.parse(paciente).id
    } else {
      paciente = sessionStorage.getItem("patient")
    }

    let convenio = statusClubflex === null
    let plano = sessionStorage.getItem("plano")
    
    if (plano) {
      plano = parseInt(plano.split("::")[1])
    }
    
    if (convenio) {
      convenio = sessionStorage.getItem("convenio").split("::")[1]
      convenio = parseInt(convenio)
    }

    let horario = sessionStorage.getItem("horario").split(":")
    if (horario[0].length < 2) {
      horario = `0${horario[0]}:${horario[1]}`
    } else {
      horario = `${horario[0]}:${horario[1]}`
    }
  

    let data = sessionStorage.getItem("date_selected")
    data = new Date(data)
    data = datePtBRLabel(data)
    data = convertBRDateToIso8601(data)
    data += "T"
    data += horario
    data += ":00Z"

    let tabelaId = statusClubflex ? 250 : parseInt(sessionStorage.getItem("tabelaId"))
    let enderecoUnidadeEscolhida = `Endereço: `

    let unidade = sessionStorage.getItem("unidade")
    if (unidade) {
      let unidadeId = unidade.split("::")[1]
      enderecoUnidadeEscolhida += JSON.parse(sessionStorage.getItem("units"))[`unid-${unidadeId}`]
        .endereco
    }


    // const [procedimento_id, especialidade_id] = sessionStorage.getItem("especialidade").split("::") 
    const [_especialidade, procedimento_id, especialidade_id] = sessionStorage.getItem("especialidade").split("::")
    const reqBody = {
      clientId: sessionStorage.getItem("client"),
      pacienteId: parseInt(paciente),
      dataAgendamento: data,
      procedimentoId: parseInt(procedimento_id),
      especialidadeId: parseInt(especialidade_id),
      localId: parseInt(sessionStorage.getItem("localId")),
      valor: convenio ? 0 : precoConsulta * 100,
      ...(convenio && { convenioId: convenio }),
      ...(plano && {planoId: plano}),
      profissionalId: profissionalId,
      tabelaId: tabelaId,
      notas: sessionStorage.getItem("obs"),
      typeTabelaId: sessionStorage.getItem("typeTabelaId")
    }

  //disparo de email para do agendamento
    await api
      .post("/schedule-store", reqBody)
      .then(e => {
        setLoaderState(false)
        const { success, content } = e.data
        if (success) {
          
          updateLead({
            FEEGOW_ID_AGENDAMENTO: content.agendamento_id
          })
          const data = JSON.parse(encryptClass.reveal(sessionStorage.getItem("patientFound")))
          
          const dataMail = {
            nome: data.nome,
            email: data.email,
            endereco: enderecoUnidadeEscolhida,
            unidade: sessionStorage.getItem("unidade").split('::')[0],
            regional: sessionStorage.getItem("unidade").split('::')[2],
            especialidade: sessionStorage.getItem("especialidade").split('::')[0],
            data: `${datePtBRLabel(new Date(sessionStorage.getItem("date_selected")))} - ${horario}`,
            valor: precoConsulta.toLocaleString("pt-br", { style: "currency", currency: "BRL" }),
            horario: horario,
            tipoDeTabela: sessionStorage.getItem("typeTabelaId"),
            tipoCliente: sessionStorage.getItem("tipoCliente")
          }

          fetch("https://tuk21bf0ec.execute-api.sa-east-1.amazonaws.com/sendMailConsultas", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dataMail) 
        });
          sessionStorage.setItem("agendamento", content.agendamento_id)
          navigate("/confirmacao")
        } else {
          alert("Não foi possível concluir o agendamento")
        }
      })
      .catch(e => {
        setLoaderState(false)
        if (axios.isAxiosError(e)) {
          alert(
            "Não foi possível agendar sua consulta. Tente novamente mais tarde."
          )
        }
      })
  }

  const mainClassName = statusClubflex ? "page-clubflex" : ""

  return (
    <main className={`site-main page-valor ${mainClassName}`}>
      <Header back_to="/agendamento">
        <h1>Resumo do agendamento</h1>
      </Header>
      <PageContent>
        <Timeline step={5} />
        <FollowUP showUnitMapLink={true} />
        {isConvenio ? (
          <Convenio selecionado={sessionStorage.getItem("convenio")} />
        ) : (
          Preco(precoConsulta)
        )}
        <form onSubmit={handle_submit}>
          <div className="input-submit is-fixed" style={{ marginBottom: "2rem"}} >
            <input type="submit" value="Agendar" />
          </div>
        </form>
        <p style={{ marginTop: "1.5rem", fontSize: "14px" }}>
          Meios de pagamentos aceitos:
        </p>
        <div className="payment-credit" style={{ paddingBottom: "6rem"}}>
          <div className="payment-methods">
            <div className="payment">
              <FaMoneyBillWaveAlt />
              Dinheiro
            </div>
            <div className="payment">
              <img src={IconPix} alt="PIX" />
              PIX
            </div>
            <div className="payment">
              <FiCreditCard />
              Cartão de Débito
            </div>
          </div>
          <div>
            Cartão de Crédito
            <img
              className="bandeiras"
              src={imgTripaBandeiras}
              alt="Bandeiras"
            />
          </div>
        </div>
      </PageContent>
    </main>
  )
}
