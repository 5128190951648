import React, { useContext } from "react"
import { GlobalContext } from "../../../contexts/global"
import "./style.css"

export default function Loader() {
  const { LoaderState } = useContext(GlobalContext)
  return (
    <div className={`site-loader ${LoaderState ? "is-loading" : ""}`}>
      <div className="loading-options">
        <div className="lds-facebook">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  )
}
