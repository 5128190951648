export default function dateWeekPtBRLabel(date) {
  var weekdays = new Array(7);
      weekdays[0] = "Domingo";
      weekdays[1] = "Segunda";
      weekdays[2] = "Terça";
      weekdays[3] = "Quarta";
      weekdays[4] = "Quinta";
      weekdays[5] = "Sexta";
      weekdays[6] = "Sábado";

  return weekdays[date.getDay()];
  
}