import React, { useContext, useState, useEffect } from "react"
import Header from "../Global/Header"
import PageContent from "../Global/PageContent"
import Image from "../Global/Image"
import Paciente from "./Paciente"
import SVGConfirmacao from "../../assets/confirmacao.svg"
import { FiPhone } from "react-icons/fi"
import { BsWhatsapp } from "react-icons/bs"
import { GlobalContext } from "../../contexts/global"
import logoSegmedic from "../../assets/logo-segmedic.svg"
import "./style.css"
import NpsRating from "./NpsRating"

export default function Confirmacao() {
  const { statusClubflex } = useContext(GlobalContext)
  // const [isBlocked, setIsBlocked] = useState(false)

  // useEffect(() => {
  //   const storedStatus = sessionStorage.getItem("statusClubflex")
  //   if (storedStatus === "BLOCKED") {
  //     setIsBlocked(true)
  //   }
  // }, [])

  const mainClassName = statusClubflex ? "page-clubflex" : ""

  return (
    <main className={`site-main page-valor ${mainClassName}`}>
      <Header back_to="/" backToHome={true} hideFollowUP={true} />
      <PageContent>
        <Image url={SVGConfirmacao} className="align-center" />
        <h3 className="align-center" style={{ marginTop: "1rem" }}>
          Agendamento realizado <br /> com sucesso!
        </h3>
        <h3 className="align-center" style={{ marginTop: "1rem", fontSize:"0.8rem" }}>
          Sua confirmação foi enviada para o email. 
        </h3>
        {/* {isBlocked && (
          <div style={{ marginTop: "1rem", color: "red", textAlign: "center" }}>
            <p style={{ color: "red", fontSize: "12px"}}>Identificamos uma pendência financeira em sua assinatura. 
              Para que você possa realizar o agendamento com desconto, 
              pedimos que entre em contato com o ClubFlex pelo número (21) 2666-5808. 
            </p>
          </div>
        )} */}
        <NpsRating />
        <Paciente />

        <p>Canais de atendimento:</p>

        <table style={{ width: "100%" }} className="central-atendimento">
          <tbody>
            <tr>
              <td>
                <FiPhone />
                Central:
              </td>
              <td>
                <a href="tel:+552126665800">
                  <strong>(21) 2666 5800</strong>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <BsWhatsapp />
                WhatsApp:
              </td>
              <td>
                <a
                  href="https://api.whatsapp.com/send?phone=2126665800"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>(21) 2666 5800</strong>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </PageContent>
      <footer className="site-footer">
        <div className="footer-content">
          <div>
            <img src={logoSegmedic} alt="Segmedic" />
            <p>CNPJ: 01.475.178/0001-51</p>
          </div>
          <div>
            <p>
              <a href="tel:+552126665800" title="Central de Atendimento">
                (21) 2666-5800
              </a>
              <br />
              <a
                href="mailto:contato@segmedic.com.br"
                title="Entre em contato pelo e-mail"
              >
                contato@segmedic.com.br
              </a>
              <br />
              {/* eslint-disable-next-line */}
              <a
                href="https://www.segmedic.com.br/codigo-de-etica/"
                title="CÓDIGO DE ÉTICA"
                target={"_blank"}
                rel={"noopener noreferrer"}
              >
                CÓDIGO DE ÉTICA
              </a>
            </p>
          </div>
        </div>
      </footer>
    </main>
  )
}
