import api from "../../services/api"
import encryptClass from "./encrypt.class"
import { dateDiff } from "../Fx/dateDiff"

export default class schedule {
  constructor() {
    this.schedule = JSON.parse(window.sessionStorage.getItem("schedule"))
  }

  // Retorna uma lista de profissionais (médicos) por unidade, filtrando pelo ID da unidade
  getMedicByUnit(unidadeId) {
    var data = {}
    Object.keys(this.schedule).forEach((index) => {
      var curScdl = this.schedule[index]
      if (curScdl.unidade_id === parseInt(unidadeId)) {
        data[curScdl.profissional_id] = curScdl.profissional_name
      }
    })
    return data
  }

  // Retorna as datas disponíveis para um médico específico em uma unidade específica
  getDatesByMedicAndUnity(MedicId, unidadeId) {
    var data = {}
    Object.keys(this.schedule).forEach((index) => {
      var curScdl = this.schedule[index]
      if (
        curScdl.profissional_id === parseInt(MedicId) &&
        curScdl.unidade_id === parseInt(unidadeId)
      ) {
        if (curScdl.horarios.length > 0) {
          data[curScdl.data] = {
            horarios: curScdl.horarios,
            localId: curScdl.local_id,
          }
        }
      }
    })
    return data
  }

  // Retorna todas as datas disponíveis para todos os médicos em uma unidade específica, aplicando um filtro baseado nos IDs dos profissionais salvos em 'filter_medicos'
  getAllDates(unidadeId) {
    var data = {}
    const filter = sessionStorage.getItem("filter_medicos").split(",").map(item => +item)

    Object.keys(this.schedule).forEach((index) => {
      var curScdl = this.schedule[index]

      if (parseInt(unidadeId) === curScdl.unidade_id) {
        if (
          Object.keys(curScdl.horarios).length > 0 &&
          filter.includes(curScdl.profissional_id)
        ) {
          data[curScdl.data] = {
            horarios: curScdl.horarios,
            localId: curScdl.local_id,
          }
        }
      }
    })
    return data
  }

  // Retorna uma lista de médicos disponíveis em uma data específica e unidade específica, considerando o convênio do paciente, caso aplicável
  async getMedicsByDate(date, unidadeId, convenio) {    
    let patientFound = sessionStorage.getItem("patientFound")
    patientFound = JSON.parse(encryptClass.reveal(patientFound))

    if (convenio && convenio.includes("::")) {
      convenio = parseInt(convenio.split("::")[1])
    }

    var result = {}

    Object.keys(this.schedule).forEach((index) => {
      var curScdl = this.schedule[index]
      if (curScdl.data === date && curScdl.unidade_id === parseInt(unidadeId)) {
        if (
          curScdl.horarios.length > 0
        ) {
          result[curScdl.profissional_id] = {
            horarios: curScdl.horarios,
            localId: curScdl.local_id,
          }
        }
      }
    })
    return result
  }

  // Retorna o nome do médico baseado no seu ID, buscando em um repositório salvo no sessionStorage
  getMedicById(ID) {
    let medicRepository = sessionStorage.getItem("professionals")
    medicRepository = medicRepository ? JSON.parse(medicRepository) : false

    let newMedicRepository = []

    if (medicRepository) {
      Object.keys(medicRepository).forEach((index) => {
        newMedicRepository[medicRepository[index]] = index
      })
      return newMedicRepository[ID]
    } else {
      return false
    }
  }

  // Filtra os profissionais com base em restrições de idade e convênio, Retorna true se o profissional cumpre os requisitos, caso contrário, retorna false
  filter(id, convenio, professionals_limits, idade) {
    const limit = professionals_limits.find(
      (item) => item.profissional_id == parseInt(id)
    )

    if (limit) {
      const idade_minima = limit.idade_minima && limit.idade_minima > idade
      const idade_maxima = limit.idade_maxima && limit.idade_maxima < idade

      const convenioHabilitado =
        convenio == null || isNaN(convenio)
          ? false
          : !limit.convenios.includes(convenio)

      return !(idade_minima || idade_maxima || convenioHabilitado)
    }
  }
}
