export default function validateCpf(cpf) {
  if (!cpf) return false

  cpf = cpf.replace(/\D/g, "")

  if (cpf.length !== 11) return false
  if (/^(\d)\1{10}$/.test(cpf)) return false

  let sum = 0
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i)
  }
  let firstVerifier = 11 - (sum % 11)
  if (firstVerifier >= 10) firstVerifier = 0  
  sum = 0
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i)
  }
  let secondVerifier = 11 - (sum % 11)
  if (secondVerifier >= 10) secondVerifier = 0

  return cpf.charAt(9) === firstVerifier.toString() && cpf.charAt(10) === secondVerifier.toString()
}
