import React, { useContext, useEffect, useState } from "react"
import Header from "../Global/Header"
import PageContent from "../Global/PageContent"
import { useNavigate, Link } from "react-router-dom"
import Timeline from "../Global/Timeline"
import DatePicker from "react-datepicker"
import ptBR from "date-fns/locale/pt-BR"
import Popup from "../Global/Popup"
import { FiCalendar, FiClock, FiArrowRight, FiEdit } from "react-icons/fi"
import datePtBRLabel from "../../inc/Fx/datePtBRLabel"
import dateWeekPtBRLabel from "../../inc/Fx/dateWeekPtBRLabel"
import imgAtendent from "../../assets/img-abstract-atendente.jpg"

import "react-datepicker/dist/react-datepicker.css"
import "./style.css"
import schedule from "../../inc/Controllers/schedule.class"
import convertBRDateToIso8601 from "../../inc/Fx/convertBRDateToIso8601"
import updateLead from "../../inc/Fx/updateLead"
import { GlobalContext } from "../../contexts/global"
import encryptClass from "../../inc/Controllers/encrypt.class"
import Paciente from "../Confirmacao/Paciente"

export default function Agendamento() {
  const navigate = useNavigate()
  const [popup_date, toggle_popup_date] = useState(false)
  const [popup_horario, toggle_popup_horario] = useState(false)
  const [date_selected, set_date_selected] = useState()
  const [date_label, set_date_label] = useState("Escolha uma data")
  const [horario, set_horario] = useState("Escolha um horário")
  const [is_popup_date_submit_disabled, disable_popup_date_submit] = useState(true)
  const { setLoaderState, patientFound } = useContext(GlobalContext)

  const { nasc, nome, id } = JSON.parse(encryptClass.reveal(patientFound))


  const [
    is_popup_horario_submit_disabled,
    disable_popup_horario_submit
  ] = useState(true)

  const [is_body_submit_disabled, disable_body_submit] = useState(true)
  const [isInputDateFilled, setInputDateFilled] = useState(false)
  const [isInputTimeFilled, setInputTimeFilled] = useState(false)
  const [localId, setLocalId] = useState()
  const [profissionalId, setProfissionalId] = useState()
  const s = new schedule()
  let enabledDates = []

  let sch = s.getDatesByMedicAndUnity(
    sessionStorage.getItem("medico").split("::")[1],
    sessionStorage.getItem("unidade").split("::")[1]
  )

  const [noMedicSelect] = useState(
    sessionStorage.getItem("medico") === "Não tenho preferência"
  )

  if (noMedicSelect) {
    sch = s.getAllDates(sessionStorage.getItem("unidade").split("::")[1])
  }

  //bloqueio de dias para nutrição relacionada a convênio
  const isNutrition = sessionStorage.getItem("especialidade").includes("Nutrição")
  const isConvenio = sessionStorage.getItem("convenio") !== null
  const isParticular = sessionStorage.getItem("convenio") === "true"

  Object.keys(sch).forEach(index => {
    let date = index.split("-")
    let currentDate = new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2]))
    if (
      !isNutrition ||
      !isConvenio ||
      isParticular ||
      (isNutrition &&
        isConvenio &&
        currentDate >= new Date(
          Date.now() + 7 * 24 * 60 * 60 * 1000))) {
      enabledDates.push(currentDate)
    }
  })

  const [noScheduleAvailable] = useState(Object.keys(sch).length < 1)

  const onChangeDate = date => {
    set_date_selected(date)
    set_date_label(`${dateWeekPtBRLabel(date)} - ${datePtBRLabel(date)}`)
    disable_popup_date_submit(false)
  }

  const onChangeHorario = horario => {
    set_horario(horario.target.value)
    disable_popup_horario_submit(false)
    disable_body_submit(false)
  }

  const [horarios, setHorarios] = useState([])

  // useEffect(() =>{
  //   const randomHorarios = horarios.slice(0, Math.floor(horarios.length/2))
  //   setHorarios(randomHorarios)
  // },[horarios])
  
  const [medicosHorarios, setMedicosHorarios] = useState([])
  const [htmlMedics, sethtmlMedics] = useState("")

  useEffect(() => {
    if (medicosHorarios) {
      let html = []
      let MedicName = ""

      Object.keys(medicosHorarios).forEach(medicId => {
        MedicName = s.getMedicById(medicId)

        html.push(
          <div className="medic-wrapper" key={medicId}>
            <p>
              <b>{MedicName}</b>
            </p>
            {medicosHorarios[medicId].horarios.map(
              horarios_callback_medics.bind(null, {
                localId: medicosHorarios[medicId].localId,
                profissionalId: medicId,
                profissionalName: MedicName
              })
            )}
          </div>
        )
      })
      sethtmlMedics(html)
    }
  }, [medicosHorarios])

  const horarios_callback = e => {
    return (
      <div className="input-radio" key={e}>
        <label>
          <input
            type="radio"
            name="consulta_horario"
            onChange={e => onChangeHorario(e)}
            value={e.substr(0, 5)}
          />
          <div className="input-label">
            <p>{e.substr(0, 5)}</p>
          </div>
        </label>
      </div>
    )
  }

  function horarios_callback_medics(data, e) {
    let { localId, profissionalId, profissionalName } = data
    return (
      <div className="input-radio" key={`${e}-${localId}-${profissionalId}`}>
        <label>
          <input
            type="radio"
            name="consulta_horario"
            onChange={e => {
              onChangeHorario(e)
              setLocalId(localId)
              setProfissionalId(`${profissionalName}::${profissionalId}`)
            }}
            value={e.substr(0, 5)}
          />
          <div className="input-label">
            <p>{e.substr(0, 5)}</p>
          </div>
        </label>
      </div>
    )
  }

  function open_popup_date(e) {
    e.preventDefault()
    toggle_popup_date(true)
  }

  function open_popup_horario(e) {
    e.preventDefault()
    toggle_popup_horario(true)
  }

  async function handle_popup_date_submit(e) {
    e.preventDefault()
    let dateSelected = convertBRDateToIso8601(datePtBRLabel(date_selected))

    if (noMedicSelect) {
      setMedicosHorarios(
        await s.getMedicsByDate(
          dateSelected,
          sessionStorage.getItem("unidade").split("::")[1],
          sessionStorage.getItem("convenio")
        )
      )
    } else {
      setHorarios(sch[dateSelected].horarios)
      setLocalId(sch[dateSelected].localId)
    }

    setInputDateFilled(true)
    setInputTimeFilled(false)
    disable_popup_horario_submit(true)
    set_horario("Escolha um horário")
    toggle_popup_date(false)
  }

  function handle_popup_horario_submit(e) {
    e.preventDefault()
    setInputTimeFilled(true)
    toggle_popup_horario(false)
  }

  const handle_submit = e => {
    e.preventDefault()
    sessionStorage.setItem("date_selected", date_selected)
    sessionStorage.setItem("horario", horario)
    sessionStorage.setItem("localId", localId)

    if (noMedicSelect) {
      sessionStorage.setItem("medico", profissionalId)
    }

    let date = convertBRDateToIso8601(datePtBRLabel(date_selected))
    date += ` ${horario}:00`
    updateLead({
      FEEGOW_DIA_AGENDAMENTO: date,
      UI_NOT_FOUND_DATE: null
    })
    navigate("/valor")
  }

  const { statusClubflex } = useContext(GlobalContext)
  const mainClassName = statusClubflex ? "page-clubflex" : ""
  

  return (
    <main className={`site-main page-agendamento ${mainClassName}`}>
      <Header back_to="/medico">
        <h1>Agendamento</h1>
      </Header>
      <PageContent>
        <Timeline step={4} />

        <Popup ID="date" open={popup_date} handle={toggle_popup_date}>
          <form onSubmit={handle_popup_date_submit}>
            <DatePicker
              includeDates={enabledDates}
              onChange={onChangeDate}
              locale={ptBR}
              inline
            />

            <div
              className={`input-submit ${
                is_popup_date_submit_disabled ? "disabled" : ""
              }`}
            >
              <button type="submit">Confirmar</button>
            </div>
          </form>
        </Popup>

        {!is_popup_date_submit_disabled && (
          <Popup ID="date" open={popup_horario} handle={toggle_popup_horario}>
            <p style={{ marginBottom: "1rem" }}>Escolha um horário</p>

            <form onSubmit={handle_popup_horario_submit}>
              {!noMedicSelect && horarios.map(horarios_callback)}
              {noMedicSelect && htmlMedics}
              <div
                className={`input-submit ${
                  is_popup_horario_submit_disabled ? "disabled" : ""
                }`}
              >
                <button type="submit">Confirmar</button>
              </div>
            </form>
          </Popup>
        )}

        <form onSubmit={handle_submit}>
          {noScheduleAvailable ? (
            <p
              style={{
                marginBottom: "2rem",
                color: "#a20",
                textAlign: "center",
                fontSize: "14px"
              }}
            >
              Infelizmente {sessionStorage.getItem("medico").split("::")[0]} não
              possui agenda disponível nesse momento.
              <br />
              <Link
                to={"/medico"}
                style={{
                  display: "inline-block",
                  textDecoration: "underline",
                  fontSize: "14px",
                  color: "#a20"
                }}
              >
                Buscar outro médico
              </Link>
            </p>
          ) : null}

          <Link
            className={`selector-format ${
              noScheduleAvailable ? "link-disabled" : null
            } ${isInputDateFilled ? "filled" : ""}`}
            style={{ marginBottom: "2rem" }}
            onClick={e => open_popup_date(e)}
          >
            <span>
              {date_label}
              <FiEdit size={8} />
            </span>
            <FiCalendar />
          </Link>

          <Link
            className={`selector-format ${
              is_popup_date_submit_disabled
                ? "link-disabled"
                : isInputTimeFilled
                ? "filled"
                : ""
            }`}
            style={{
              marginBottom: "2rem"
            }}
            onClick={e => open_popup_horario(e)}
          >
            <span>
              {horario}
              <FiEdit size={8} />
            </span>
            <FiClock />
          </Link>

          <a
            href={`https://wa.me/552126665800?text=Olá! Quero tirar dúvidas sobre o Agendamento Online.`}
            className="cta-horario"
            onClick={() => {
              updateLead({
                UI_NOT_FOUND_DATE: 1
              })
            }}
          >
            <div className="link-wrapper">
              <div
                className="image"
                style={{ backgroundImage: `url("${imgAtendent}")` }}
              ></div>
              <div className="text">
                <h3>Não encontrou a data ou horário desejado na unidade preferida?</h3>
                <h3>Verifique disponibilidade em outras unidades.</h3>
                <span className="submitHelp">Fale Conosco</span>
              </div>
            </div>
          </a>

          <div
            className={`input-submit ${
              is_body_submit_disabled ? "disabled" : ""
            }`}
          >
            <button type="submit">
              Prosseguir
              <FiArrowRight />
            </button>
          </div>
        </form>
      </PageContent>
    </main>
  )
}
