import React, { useContext, useEffect, useState } from "react"
import Header from "../Global/Header"
import PageContent from "../Global/PageContent"
import { Link, useNavigate } from "react-router-dom"
import Timeline from "../Global/Timeline"
import { FiArrowRight, FiChevronDown, FiEdit, FiSearch } from "react-icons/fi"
import Popup from "../Global/Popup/"
import removerAcentos from "../../inc/Fx/removerAcentos"
import toggleRef from "../../inc/Fx/toggleRef"
import api from "../../services/api"
import updateLead from "../../inc/Fx/updateLead"
import dateISO8601 from "../../inc/Fx/dateISO8601"
import axios from "axios"
import { GlobalContext } from "../../contexts/global"
import encryptClass from "../../inc/Controllers/encrypt.class"
import { dateDiff } from "../../inc/Fx/dateDiff"
import Alert from "../Global/Alert"
import TelemedicinaPhoto from "../../assets/telemedicina-ag.png"
import icoTel from "../../assets/tel.svg"
import "./style.css"

export default function Especialidade() {
  const [popup_especialidade, toggle_popup_especialidade] = useState(false)
  const [especialidade, set_especialidade] = useState("Escolha a especialidade")

  const [alertPediatria, setAlertPediatria] = useState(false)
  const [alertGeriatria, setAlertGeriatria] = useState(false)
  const [alertNeuropediatria, setAlertNeuropediatria] = useState(false)
  
  const [alertNutricao, setAlertNutricao] = useState(false)
  const [isNutricaoChecked, setIsNutricaoChecked] = useState(false)
  const [hasConvenio, setHasConvenio] = useState(false)
  const [isParticular, setIsParticular] = useState(false)
  const [nutricaoConfirmed, setNutricaoConfirmed] = useState(false)

  const [alertPreNatal, setAlertPreNatal] = useState(false)
  const [isAlertPreNatalChecked, setIsAlertPreNatalChecked] = useState(false)
  const [isAlertPreNatalConfirmed, setIsAlertPreNatalConfirmed] = useState(false)

  const [isOpen, onClose] = useState(false)
  const [agendamentos, setAgendamentos] = useState([])

  const [idade, set_idade] = useState(0)
  const [is_responsavel, toggle_responsavel] = useState(false)
  const [nome_paciente, set_nome_paciente] = useState("")

  const [is_popup_submit_disabled, disable_popup_submit] = useState(true)
  const [is_body_submit_disabled, disable_body_submit] = useState(true)
  const navigate = useNavigate()
  const [isInputFilled, setInputFilled] = useState(false)
  const { setLoaderState, statusClubflex, patientFound } = useContext(GlobalContext)

  const { nasc, nome, id } = JSON.parse(encryptClass.reveal(patientFound))

  //Alerta de Erro
  const [alertError, setAlertError] = useState(false)
  
  //condições de telemedicina
  const [alertTelemedicina, setAlertTelemedicina] = useState(false)
  const [selectedProcedimentoId, setSelectedProcedimentoId] = useState(null)
  const [selectedTelemedicinaId, setSelectedTelemedicinaId] = useState(null)

  const search_handle = (text) => {
    toggleRef({
      querySelector: "especialidade",
      text: removerAcentos(text)
    })
  }

  let htmlEspecialidades = []
  const [especialidadesData, setEspecialidadesData] = useState()
  const [especialidades, setEspecialidades] = useState([])

  useEffect(() => {
    setLoaderState(true)
    const indexEspecialidades = async () => {
      let isClubFlex = sessionStorage.getItem("statusClubflex") !== null
      let isConvenio = sessionStorage.getItem("convenio")

      if (isConvenio !== null) {
        const convenioSplit = isConvenio.split("::")
        if (convenioSplit.length > 1) {
          const convenio = convenioSplit[1]
          
          setHasConvenio(true)
          if (convenio.toLowerCase() === "true") {
            setIsParticular(true)
          }
        }
      }

      set_idade(dateDiff(nasc))
      set_nome_paciente(nome)
        
      await api
        .post("/procedures", {
          clubflex: isClubFlex,
          ...(!isClubFlex && isConvenio  
            ? { insurance: parseInt(isConvenio.split("::")[1]) }
            : {})
        })
        .then(async (feegowResp) => {
          const { success, content } = feegowResp.data
          if (success) {
            setEspecialidadesData(content)
          } else {
            setEspecialidadesData(false)
            return "Não foi possível buscar especialidades. Tente novamente mais tarde"
          }
        })
        .catch(() => {
          setLoaderState(false)
          setEspecialidadesData(false)
          return "Não foi possível buscar especialidades. Tente novamente mais tarde"
        })
    }

    api.get("/schedule", {
      params: {
        pacienteId: id
      }
    })
      .then(res => setAgendamentos(res.data.content.filter(item => [1, 7].includes(item.status_id))))
      .catch(() => {
      })
    indexEspecialidades()
  }, [])

  useEffect(() => {
    async function callEspecialidadesData() {
      if (especialidadesData !== undefined) {
        await api.get("/procedures").then((resp) => {
          const { content } = resp.data
          let procedimentos = []

          Object.keys(especialidadesData).forEach((index) => {
            let current = especialidadesData[index]

            Object.keys(content).forEach((index2) => {
              if (current.procedimento_id === content[index2].procedimento_id) {
                procedimentos.push({
                  ...content[index2],
                  especialidade_id: current.especialidade_id[0]
                })
              }
            })
          })

          // Sorting
          let sorting = {}
          Object.keys(procedimentos).forEach((index) => {
            sorting[procedimentos[index].procedimento_name] = {
              procedimento_id: procedimentos[index].procedimento_id,
              especialidade_id: procedimentos[index].especialidade_id
            }
          })

          let sorted = Object.keys(sorting)
            .sort()
            .reduce((obj, key) => {
              obj[key] = sorting[key]
              return obj
            }, {})

          Object.keys(sorted).forEach((index) => {
            htmlEspecialidades.push(
              <div
                key={sorted[index].procedimento_id}
                className="input-radio-capsule list_content"
                especialidade={removerAcentos(index)}
              >
                <label>
                  <input
                    type="radio"
                    name="input-especialidade"
                    value={`${index}::${sorted[index].procedimento_id}::${sorted[index].especialidade_id}`}

                    onChange={async (e) => {
                      const value = e.target.value
                      disable_popup_submit(true);

                      const procedimento = procedimentos.find(p => p.procedimento_id === parseInt(value.split("::")[1]))                      
                      const exist = agendamentos.find(item => item.procedimento_id === parseInt(value.split("::")[1]))
                      const convenio = sessionStorage.getItem("convenio")
                      const statusClubflex = sessionStorage.getItem("statusClubflex")
                      const planosAceitosTelemedicina = ["10", "16", "14", "1378"]
                      const convenioId = convenio ? convenio.split("::")[1] : null

                      if (exist) {
                        onClose(true)
                        disable_popup_submit(true)
                        set_especialidade("Escolha a especialidade")
                      } else {
                        set_especialidade(value)
                        disable_popup_submit(false)
                        disable_body_submit(false)
                        if(procedimento && procedimento.telemedicina_id && (
                            !convenio || 
                            convenio === "true" ||
                            statusClubflex || 
                            planosAceitosTelemedicina.includes(convenioId))
                          ){
                          setSelectedProcedimentoId(procedimento.procedimento_id)
                          setSelectedTelemedicinaId(procedimento.telemedicina_id)
                          disable_body_submit(true)
                          setAlertTelemedicina(true)
                        } else if (value.includes("Pediatria") && idade >= 14) {
                          setAlertPediatria(true)
                          disable_body_submit(true)
                        } else if (value.includes("Nutrição") && hasConvenio && !isParticular){
                          setAlertNutricao(true)
                          disable_body_submit(true)
                        } else if (value.includes("Pré-natal") && idade < 18 ) {
                          setAlertPreNatal(true)
                          disable_body_submit(true)
                        } else if (value.includes("Geriatria") && idade < 60) {
                          setAlertGeriatria(true)
                          disable_body_submit(true)
                        } else if (value.includes("Neuropediatria") && idade > 13) {
                          setAlertNeuropediatria(true)
                          disable_body_submit(true)
                        } else {
                          toggle_popup_especialidade(false)
                        }
                      }
                    }}
                  />
                  <div className="icon">
                    <span></span>
                  </div>
                  <div className="label">{index}</div>
                </label>
              </div>
            )
          })
          setEspecialidades(htmlEspecialidades)
          setLoaderState(false)
        })
      }
    }

    callEspecialidadesData()
  }, [especialidadesData, agendamentos])

  function open_popup(e) {
    e.preventDefault()
    toggle_popup_especialidade(true)
  }
  const closeAllPopups = () => {
    setAlertTelemedicina(false)
    setAlertPediatria(false)
    setAlertGeriatria(false)
    setAlertNeuropediatria(false)
    setAlertNutricao(false)
    setAlertPreNatal(false)
    toggle_popup_especialidade(false)
  }

  const handle_submit = async (e) => {
    e.preventDefault()
    setLoaderState(true)

    updateLead({
      FEEGOW_ESPECIALIDADE: especialidade.split("::")[0],
      PACIENTE_IDADE: idade,
      PACIENTE_RESPONSAVEL: is_responsavel,
      PACIENTE_NOME: nome_paciente
    })

    sessionStorage.setItem("obs", `paciente: ${nome_paciente}\nidade: ${idade} \n #agWebConsultas`)
    sessionStorage.setItem("especialidade", especialidade)
    sessionStorage.removeItem("localId")
    sessionStorage.removeItem("unidade")
    sessionStorage.removeItem("horario")
    sessionStorage.removeItem("medico")
    sessionStorage.removeItem("date_selected")

    const apiArgs = {
      procedimentoId: parseInt(especialidade.split("::")[1]),
      startDate: dateISO8601(true),
      endDate: dateISO8601(true, 45)
    }

    await api
      .post("/schedule", apiArgs)
      .then((resp) => {
        const { content, professionals, units } = resp.data
        sessionStorage.setItem("schedule", JSON.stringify(content))
        sessionStorage.setItem("units", JSON.stringify(units))
        sessionStorage.setItem("professionals", JSON.stringify(professionals))
        setLoaderState(false)
        navigate("/unidade")
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          setLoaderState(false)
          setAlertError(true)
        }
      })
  }

  //alertas de condições para especialidades
  const especialidadeConditions = {
    "Pediatria::245::6": {alert: setAlertPediatria, condition: () => true},
    "Nutrição::262::22": {alert: setAlertNutricao, condition: () => hasConvenio && !isParticular},
    "Pré-natal::100229::21": {alert: setAlertPreNatal, condition: () => isAlertPreNatalChecked && idade < 18},
    "Geriatria::268::14": {alert: setAlertGeriatria, condition: () => true},
    "Neuropediatria::9585::58": {alert: setAlertNeuropediatria, condition: () => true}
  }

  const handle_popup_especialidade_submit = (e) => {
    e.preventDefault()
    let bodySubmit = false
    setInputFilled(true)

    if (especialidadeConditions[especialidade] && especialidadeConditions[especialidade].condition()){
      especialidadeConditions[especialidade].alert(true)    
      bodySubmit = true
    }
    disable_body_submit(bodySubmit)
    if (!bodySubmit) {
      toggle_popup_especialidade(false)
    }
  }

  const handle_alert_prenatal = (e) => {
    e.preventDefault()
    setAlertPreNatal(false)
    setIsAlertPreNatalConfirmed(true)
    disable_body_submit(false)
    toggle_popup_especialidade(false)
    updateLead({ VALID_POPUP: true })
  }

  const handle_alert_nutricao = (e) => {
    e.preventDefault()
    setAlertNutricao(false)
    setNutricaoConfirmed(true)
    disable_body_submit(false)
    toggle_popup_especialidade(false)
    updateLead({ VALID_POPUP: true })
  }
  const handle_outro_cpf = () =>{
    setAlertPediatria(false)
    localStorage.setItem("showPopupOnReturn", "true")
    navigate("/")
    updateLead({ VALID_POPUP: true })
  }
  const handle_outra_especialidade = () =>{
    setAlertPediatria(false)
    setAlertGeriatria(false)
    setAlertNeuropediatria(false)
    updateLead({ VALID_POPUP: true })
  }

  const mainClassName = statusClubflex ? "page-clubflex" : ""
  return (
    <>
      <main className={`site-main page-especialidade ${mainClassName}`}>
        <Header back_to="/cadastro">
          <h1>Atenção</h1>
        </Header>
        <PageContent>
          <Timeline step={1} />
{/* Alerta de Erro */}
          <Alert
            isOpen={alertError}
            onClose={() => setAlertError(false)}
            title={"Desculpe"}
            showOkButton={false}
          >


              <p>Estamos com instabilidade no sistema.<br/> 
              Por favor entre em contato com o nosso time pelo número:
              </p>
              <div className="callTel" style={{display:"flex", justifyContent:"center"}}>
                <img style={{maxWidth: "1.5rem"}} className="icoTel" src={icoTel}/>
                <a href="tel:+552126665800">(21) 2666-5800</a>
              </div>
            
          </Alert>

{/* Alerta para Telemedicina */}
          <Alert
            isOpen={alertTelemedicina}
            onClose={closeAllPopups}
            showOkButton={false}
          >
            <img style={{ padding:"0 0.6rem", marginTop: "-16px" }} src={TelemedicinaPhoto} alt="Telemedicina"/>
            <h1 style={{ padding:"0 1rem", marginTop:"12px" }}>Telemedicina</h1>
            <p style={{ fontSize:"17px", fontWeight:"400" }}>Agora você pode agendar sua Consulta em uma Unidade e ser atendido por um médico de outra Unidade Segmedic.</p>
            <div style={{ padding:"1.5rem 0 1rem", display:"flex", flexDirection:"row", justifyContent:"center", gap:"1rem"}}>
              <button
                style={{ padding:"1.5rem", fontSize:"17px" }} 
                onClick={() => {
                  const especialidadeName = especialidade.split("::")[0];
                  const especialidadeId = especialidade.split("::")[2];
                  set_especialidade(`Telemedicina ${especialidadeName}::${selectedTelemedicinaId}::${especialidadeId}`);
                  disable_body_submit(false);
                  closeAllPopups();
              }}>Telemedicina</button>
              <button 
                style={{ fontSize:"17px" }}
                onClick={() => {
                  const especialidadeName = especialidade.split("::")[0];
                  const especialidadeId = especialidade.split("::")[2];
                  set_especialidade(`${especialidadeName}::${selectedProcedimentoId}::${especialidadeId}`);
                  disable_body_submit(false);
                  closeAllPopups();
              }}>Presencial</button>
            </div>
          </Alert>

{/*Alerta de agendamento Duplicado*/}
          <Alert isOpen={isOpen} onClose={onClose} title="Alerta">
            <p>Você já possui um Agendamento para esta Especialidade.</p>
          </Alert>

{/*Alerta Nutrição*/}
          <Alert
            isOpen={alertNutricao}
            onClose={() => setAlertNutricao(false)}
            title="Atenção!"
            disabled={!isNutricaoChecked}
            showOkButton={false}
          >
            <p>Para realizar o atendimento com nutricionista, é necessário enviar o encaminhamento médico para autorização junto a seu convênio.</p>
            <div className="alert-especialidade">
              <label>
                <input
                  type="checkbox"
                  checked={isNutricaoChecked}
                  onChange={(e) => setIsNutricaoChecked(e.target.checked)}
                />
                <p> Estou ciente do encaminhamento do pedido. </p>
              </label>
              <div className="alert-content">
                <button onClick={handle_alert_nutricao} disabled={!isNutricaoChecked}>
                  Ok
                </button>
              </div>
            </div>
          </Alert>

{/*Alerta Pre-Natal*/}
          <Alert
            isOpen={alertPreNatal}
            onClose={() => setAlertPreNatal(false)}
            title="Atenção!"
            disabled={!isAlertPreNatalChecked}
            showOkButton={false}
          >
            <p>Em casos de atendimento para pacientes menores de idade, é necessário estar acompanhado de um representante legal.</p>
            <div className="alert-especialidade">
              <label>
                <input
                  type="checkbox"
                  checked={isAlertPreNatalChecked}
                  onChange={(e) => setIsAlertPreNatalChecked(e.target.checked)}
                />
                <p>Estou ciente dessa obrigatoriedade.</p>
              </label>
              <div className="alert-content">
                <button onClick={handle_alert_prenatal} disabled={!isAlertPreNatalChecked}>
                  Ok
                </button>
              </div>
            </div>
          </Alert>

{/*Alerta Pediatria*/}
          <Alert
            isOpen={alertPediatria}
            onClose={() => setAlertPediatria(false)}
            title="Atenção!"
            showOkButton={false}
          >
            <p>Não é possível agendar consultas de Pediatria para pacientes com mais de 13 anos.</p>
            <p>Por favor escolha uma das opções abaixo.</p>
            <div className="alert-content">
              <button onClick={handle_outro_cpf}>Outro CPF</button>
              <button onClick={handle_outra_especialidade} >Outra especialidade</button>
            </div>
          </Alert>          

{/*Alerta Geriatria*/}
          <Alert
            isOpen={alertGeriatria}
            onClose={() => setAlertGeriatria(false)}
            title="Atenção!"
            showOkButton={false}
          >
            <p>Não é possível agendar consultas de Geriatria para pacientes abaixo de 60 anos.</p>
            <p>Por favor escolha uma das opções abaixo.</p>
            <div className="alert-content">
              <button onClick={handle_outro_cpf}>Outro CPF</button>
              <button onClick={handle_outra_especialidade} >Outra especialidade</button>
            </div>
          </Alert>

{/*Alerta Neuropediatria*/}
          <Alert
            isOpen={alertNeuropediatria}
            onClose={() => setAlertNeuropediatria(false)}
            title="Atenção!"
            showOkButton={false}
          >
            <p>Não é possível agendar consultas de Neuropediatria para pacientes com mais de 13 anos.</p>
            <p>Por favor escolha uma das opções abaixo.</p>
            <div className="alert-content">
              <button onClick={handle_outro_cpf}>Outro CPF</button>
              <button onClick={handle_outra_especialidade} >Outra especialidade</button>
            </div>
          </Alert>

{/* Renderiza todas as Especialidades */}
          <Popup
            ID="especialidade"
            open={popup_especialidade}
            handle={toggle_popup_especialidade}
          >
            <form onSubmit={handle_popup_especialidade_submit}>
              <div
                className="input-text input-search "
                style={{ marginBottom: "1.75rem" }}
              >
                <label htmlFor="search-especialidade" style={{ marginTop: "2rem" }}>
                  <input
                    type="text"
                    name="search-especialidade"
                    id="search-especialidade"
                    onKeyUp={(e) => search_handle(e.target.value)}
                    placeholder="Buscar especialidade"
                  />
                  <FiSearch />
                </label>
              </div>
              <div>
                {especialidades}
              </div>
            </form>
          </Popup>

          <form onSubmit={handle_submit}>
            <Link
              className={`selector-format ${isInputFilled ? "filled" : ""}`}
              onClick={(e) => open_popup(e)}
            >
              <span>
                {especialidade.split("::")[0]}
                <FiEdit size={8} />
              </span>
              <FiChevronDown />
            </Link>

            <div className="returnExame">
              {/* aviso de consulta */}

              <div className="link-wrapper">
                <div className="text">
                  <h2 className="titleBox">Olá, {nome.split(" ")[0]}</h2>
                  <h4>
                    {" "}
                    <p>Informamos que para agendar <strong>Revisão de Consulta</strong></p>
                  </h4>

                  <div className="callTel">
                    <a
                      href={`https://wa.me/552126665800?text=Olá, quero agendar consulta!`}
                      onClick={() => {
                        updateLead({
                          UI_NOT_FOUND_DATE: 1
                        })
                      }}
                    >
                      <p> Entre em contato pelo whatsapp: (21)2666-5800.</p>
                    </a>
                    <img className="icoTel" src="./whatsapp-line.svg" />
                  </div>
                </div>
              </div>
            </div>
            <div className={`input-submit ${is_body_submit_disabled && !nutricaoConfirmed && !isAlertPreNatalConfirmed ? "disabled" : ""}`}>
              <button type="submit">
                Prosseguir
                <FiArrowRight />
              </button>
            </div>
          </form>
        </PageContent>
      </main>
    </>
  )
}
