import React from "react"
import { FiSearch } from "react-icons/fi"
import { FaWhatsapp } from "react-icons/fa";
import toggleRef from "../../../inc/Fx/toggleRef"
import removerAcentos from "../../../inc/Fx/removerAcentos"
import "./style.css"

export function List({ handle, isDisabled, children, title }) {
  const search_handle = (text) => {
    toggleRef({
      querySelector: "medico",
      text: removerAcentos(text),
    })
  }
  return (
    <form onSubmit={handle}>
      <h2>Buscar</h2>
      <div
        className="input-text input-search"
        style={{ marginBottom: "1 rem" }}
      >
        <label htmlFor="search-convenio">
          <input
            type="text"
            name="search-convenio"
            id="search-convenio"
            onKeyUp={(e) => search_handle(e.target.value)}
            placeholder="Buscar"
          />
          <FiSearch />
        </label>
      </div>
      <div className="duvidas_convenio">
        <p>Alguma dúvida? Entre em<br/> 
          contato:<a href={"https://wa.me/552126665800?text=Olá, tenho duvidas sobre os convênios!"}>(21) 2666-5800</a> .
        </p>
        <FaWhatsapp className="whatsapp_icon" />
      </div>
      <h2 style={{ marginBottom: "1.5rem" }}>{title}</h2>
      <div className="content_list_convenios">
        {children}
      </div>
    </form>
  )
}
