import React from "react";
import "./style.css"

export default function Grid({corDeFundo, id, children }) {
  return (
    <div className="grid-items" id={id} style={
        {
          backgroundColor: `${corDeFundo}`
        }
      }>
      {children}
    </div>
  );
}