import React, { useContext, useEffect, useState } from "react"
import Header from "../Global/Header"
import PageContent from "../Global/PageContent"
import { useNavigate, Link } from "react-router-dom"
import Timeline from "../Global/Timeline"
import { FiArrowRight, FiChevronDown, FiSearch, FiEdit } from "react-icons/fi"
import Popup from "../Global/Popup/"
import removerAcentos from "../../inc/Fx/removerAcentos"
import toggleRef from "../../inc/Fx/toggleRef"
import schedule from "../../inc/Controllers/schedule.class"
import updateLead from "../../inc/Fx/updateLead"
import { GlobalContext } from "../../contexts/global"
import api from "../../services/api"
import Alert from "../Global/Alert"
import encryptClass from "../../inc/Controllers/encrypt.class"
import { dateDiff } from "../../inc/Fx/dateDiff"

export default function Medico() {
  const [popup_medico, toggle_popup_medico] = useState(false)
  const [medico, set_medico] = useState("Escolha o médico")
  const [is_popup_submit_disabled, disable_popup_submit] = useState(true)
  const [is_body_submit_disabled, disable_body_submit] = useState(true)
  const [professionals_limits, set_professionals_limits] = useState([])

  const navigate = useNavigate()
  const [isInputFilled, setInputFilled] = useState(false)

  const medicos = new schedule().getMedicByUnit(
    sessionStorage.getItem("unidade").split("::")[1]
  )

  let convenio = sessionStorage.getItem("convenio")
  if (convenio && convenio.includes("::")) {
    convenio = parseInt(convenio.split("::")[1])
  }
  let patientFound = sessionStorage.getItem("patientFound")
  patientFound = JSON.parse(encryptClass.reveal(patientFound))
  const idade = dateDiff(patientFound.nasc)
  
  useEffect(() => {
    api
      .get("/professionals/limit")
      .then(({ data }) => set_professionals_limits(data.content))
      .catch((err) => {})
  }, [])

  const search_handle = (text) => {
    toggleRef({
      querySelector: "medico",
      text: removerAcentos(text),
    })
  }

  const handle_medico = async (event) => {
    set_medico(event.target.value)
    disable_popup_submit(false)
    toggle_popup_medico(false)
    disable_body_submit(false)
  }
  const filter = (id) => {
    const limit = professionals_limits.find(
      (item) => item.profissional_id == parseInt(id)
    )

    if (limit) {
      const idade_minima = limit.idade_minima && limit.idade_minima > idade
      const idade_maxima = limit.idade_maxima && limit.idade_maxima < idade
      const sexo = limit.sexo && limit.sexo != patientFound.sexo

      const convenioHabilitado =
        convenio == null || isNaN(convenio)
          ? false
          : !limit.convenios.includes(convenio)

      return !(idade_minima || idade_maxima || convenioHabilitado || sexo)
    }
  }
  
  // Sorting
  let sorting = {}
  Object.keys(medicos).forEach((index) => {
    sorting[medicos[index]] = index
  })

  let sorted = Object.keys(sorting)
    .sort()
    .reduce((obj, key) => {
      const filtered = filter(sorting[key])
      if (filtered || filtered === undefined) {
        obj[key] = sorting[key]
      }
      
      return obj
    }, {})

  let htmlMedicos = []
  const keys = Object.keys(sorted)

  if (keys.length > 1) {
    htmlMedicos.push(
      <div
        key={0}
        className="input-radio-capsule"
        style={{ marginBottom: "8px" }}
        medico={"Não tenho preferência"}
      >
        <label>
          <input
            type="radio"
            name="input-medico"
            value={"Não tenho preferência"}
            onChange={(e) => {
              set_medico(e.target.value)
              handle_medico(e)
              disable_popup_submit(false)
            }}
          />
          <div className="icon">
            <span></span>
          </div>
          <div className="label">Não tenho preferência por médico</div>
        </label>
      </div>
    )
  }

  keys.forEach((index) => {
    htmlMedicos.push(
      <div
        key={sorted[index]}
        className="input-radio-capsule"
        style={{ marginBottom: "8px" }}
        medico={removerAcentos(index)}
      >
        <label>
          <input
            type="radio"
            name="input-medico"
            value={`${index}::${sorted[index]}`}
            onChange={handle_medico}
          />
          <div className="icon">
            <span></span>
          </div>
          <div className="label">{index}</div>
        </label>
      </div>
    )
  })

  function open_popup(e) {
    e.preventDefault()
    toggle_popup_medico(true)
  }

  const handle_submit = (e) => {
    e.preventDefault()
    sessionStorage.setItem("medico", medico)
    sessionStorage.setItem("filter_medicos", Object.values(sorted))
    updateLead({
      FEEGOW_PROFISSIONAL: medico.split("::")[0],
    })
    navigate("/agendamento")
  }

  const handle_popup_submit = (e) => {
    e.preventDefault()
    disable_body_submit(false)
    setInputFilled(true)
    toggle_popup_medico()
  }

  const { statusClubflex } = useContext(GlobalContext)
  const mainClassName = statusClubflex ? "page-clubflex" : ""

  return (
    <main className={`site-main page-medico ${mainClassName}`}>
      <Header back_to="/unidade">
        <h1>Médico</h1>
      </Header>
      <PageContent>
        <Timeline step={3} />
        <Popup ID="medico" open={popup_medico} handle={toggle_popup_medico}>
          <form onSubmit={handle_popup_submit}>
            <div
              className="input-text input-search"
              style={{ marginBottom: "1.75rem" }}
            >
              <label htmlFor="search-medico">
                <input
                  type="text"
                  name="search-medico"
                  id="search-medico"
                  onKeyUp={(e) => search_handle(e.target.value)}
                  placeholder="Buscar médico"
                />
                <FiSearch />
              </label>
            </div>
            {htmlMedicos}
            {/* <div
              className={`input-submit ${
                is_popup_submit_disabled ? "disabled" : ""
              }`}
            >
              <button type="submit">Confirmar</button>
            </div> */}
          </form>
        </Popup>

        <form onSubmit={handle_submit}>
          <Link
            className={`selector-format ${isInputFilled ? "filled" : ""}`}
            onClick={(e) => open_popup(e)}
          >
            <span>
              {medico.split("::")[0]}
              <FiEdit size={8} />
            </span>
            <FiChevronDown />
          </Link>

          <div
            className={`input-submit ${
              is_body_submit_disabled ? "disabled" : ""
            }`}
          >
            <button type="submit">
              Prosseguir
              <FiArrowRight />
            </button>
          </div>
        </form>
      </PageContent>
    </main>
  )
}
