import axios from "axios"

const api = axios.create({
  // develop
  // baseURL: process.env.REACT_APP_ENV_ENDPOINT || "https://1bk5ffzlch.execute-api.sa-east-1.amazonaws.com"

  // prod
  baseURL: process.env.REACT_APP_ENV_ENDPOINT || "https://tuk21bf0ec.execute-api.sa-east-1.amazonaws.com"

  //local host
  // baseURL: process.env.REACT_APP_ENV_ENDPOINT || "http://localhost:4000"
})

export default api
