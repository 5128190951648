import React, { useState } from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Home from "./pages/Home"
import Cadastro from "./pages/Cadastro"
import CadastroEncontrado from "./pages/Cadastro/Encontrado"
import Especialidade from "./pages/Especialidade"
import Unidade from "./pages/Unidade"
import Medico from "./pages/Medico"
import Agendamento from "./pages/Agendamento"
import Valor from "./pages/Valor"
import Confirmacao from "./pages/Confirmacao"
import Convenio from "./pages/Convenio"
import encryptClass from "./inc/Controllers/encrypt.class"
import Loader from "./pages/Global/Loader"

import { GlobalContext } from "./contexts/global"

export default function SiteRoutes() {
  const [patientFound, setPatientFound] = useState(
    sessionStorage.getItem("patientFound")
  )
  const [statusClubflex, setStatusClubflex] = useState(
    sessionStorage.getItem("statusClubflex")
  )

  const [LoaderState, setLoaderState] = useState(false)

  var patient = patientFound
    ? JSON.parse(encryptClass.reveal(patientFound))
    : false

  return (
    <GlobalContext.Provider
      value={{
        statusClubflex,
        setStatusClubflex,
        patientFound,
        setPatientFound,
        LoaderState,
        setLoaderState
      }}
    >
      <Loader />
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route
            path="/cadastro"
            element={
              typeof patient !== "object" ? (
                <Cadastro />
              ) : (
                <CadastroEncontrado />
              )
            }
          />
          <Route path="/especialidade" element={<Especialidade />} />
          <Route path="/unidade" element={<Unidade />} />
          <Route path="/medico" element={<Medico />} />
          <Route path="/agendamento" element={<Agendamento />} />
          <Route path="/valor" element={<Valor />} />
          <Route path="/confirmacao" element={<Confirmacao />} />
          <Route path="/convenio" element={<Convenio />} />
        </Routes>
      </Router>
    </GlobalContext.Provider>
  )
}
