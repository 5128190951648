import React from "react"
import "./style.css"
import { RiWhatsappFill } from "react-icons/ri"


const PopupWhats = () => {
    return (
        <div className="pop_contain">
            <h2 className="title_pop">
                Agende agora
            </h2>
            <p className="text_pop">Em breve essa funcionalidade estará disponível</p>
            

            <div className="content_submit">
                <p>enquanto isso agende pelo nosso whatsapp:</p>
            </div>
            <div className="icone_pop">
                <a className="link" href="https://bit.ly/3I48fa3" target="_blank" rel="noopener noreferrer">
                    <RiWhatsappFill className="icone" />
                    <p className="link">WhatsApp</p>
                </a>
            </div>
            <div className="pop_tel"><p>ou ligue para:</p><strong>(21) 2666 5800</strong></div>
        </div>

    )
}

export default PopupWhats