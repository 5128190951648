import React from "react"
import "./style.css"
import getConvenioLogo from "../../../inc/Fx/getConvenioLogo"

export default function Convenio({ selecionado }) {
  return (
    <div className="convenio-selected">
      <figure>
        <img
          src={getConvenioLogo(selecionado.split("::")[1])}
          alt={selecionado.split("::")[0]}
        />
      </figure>
      <h3>{selecionado.split("::")[0]}</h3>
    </div>
  )
}
