import React, { useContext, useEffect, useState } from "react"
import Header from "../Global/Header"
import PageContent from "../Global/PageContent"
import { useNavigate, Link } from "react-router-dom"
import Timeline from "../Global/Timeline"
import { FiArrowRight, FiChevronDown, FiSearch, FiEdit } from "react-icons/fi"
import Popup from "../Global/Popup/"
import removerAcentos from "../../inc/Fx/removerAcentos"
import toggleRef from "../../inc/Fx/toggleRef"
import { GlobalContext } from "../../contexts/global"
import api from "../../services/api"
import PopupEspecialidadeEspontanea from "../Unidade/PopupEspepecialidade"
import "./style.css"
import updateLead from "../../inc/Fx/updateLead"

export default function Unidade() {
  const [popup_unidade, toggle_popup_unidade] = useState(false)
  const [unidade, set_unidade] = useState("Escolha a unidade")
  const [is_popup_submit_disabled, disable_popup_submit] = useState(true)
  const [is_body_submit_disabled, disable_body_submit] = useState(true)
  const navigate = useNavigate()
  const [isInputFilled, setInputFilled] = useState(false)
  const [filterUnits, setFilterUnits] = useState()
  const [htmlUnidades, setHtmlUnidades] = useState()
  const {setLoaderState} = useContext(GlobalContext)

  const unidadesRaw = window.sessionStorage.getItem("units");
  const unidadesObj = unidadesRaw ? JSON.parse(unidadesRaw) : {};
  const unidades = Object.keys(unidadesObj).map(key => unidadesObj[key]);
  console.log("Parsed Unidades:", unidades);

  const convenio = window.sessionStorage.getItem("convenio")
  
  const [isEspecialidadeEspontanea, setEspecialidadeEspontanea] = useState(false)

  const search_handle = (text) => {
    toggleRef({
      querySelector: "unidade",
      text: removerAcentos(text),
    })
  }

  
  useEffect(() => {
    if (convenio && convenio.includes("::")) {
      const idCovenio = convenio.split("::")[1]
      setLoaderState(true)
      api
        .get("/units?convenio=" + idCovenio)
        .then((response) => {
          if (response.data.units)
            setFilterUnits(response.data.units)
            setLoaderState(false)
        })
        .catch((err) => {
          console.log(err)
          setLoaderState(false)
        })
    }
  }, [])

  useEffect(() => {
    const html = []
    Object.keys(unidades).forEach((index) => {
      if (filterUnits && !filterUnits.includes(unidades[index].id)) {
        return
      }

      html.push(
        <div
          key={index}
          className="input-radio-capsule"
          style={{ marginBottom: "8px" }}
          unidade={removerAcentos(unidades[index].regional)}
        >
          <label>
            <input
              type="radio"
              name="input-unidade"
              value={`${unidades[index].nome}::${unidades[index].id}::${unidades[index].regional}`}
              onChange={(e) => {
                const value = e.target.value;
                const parts = value.split("::");
                const selectedUnidadeId = parseInt(parts[1], 10)
                const selectedUnidade = unidades.find(unit => unit.id === selectedUnidadeId);
              
                if (selectedUnidade) {
                  set_unidade(value);
                  if (selectedUnidade.espontanea) {
                    setEspecialidadeEspontanea(true)
                    disable_popup_submit(true)
                  } else {
                    disable_popup_submit(false)
                    disable_body_submit(false)
                    toggle_popup_unidade(false)
                  }
                }
              }}              
              
            />
            <div className="icon">
              <span></span>
            </div>
            <div id="adress" className="label label_adress">
              <p>
                {unidades[index].regional}
                <br />
                <span>{unidades[index].nome}<br />
                {unidades[index].endereco}</span>
              </p>
            </div>
          </label>
        </div>
      )
    })

    setHtmlUnidades(html)
  }, [filterUnits])

  function open_popup(e) {
    e.preventDefault()
    toggle_popup_unidade(true)
  }

  const handle_submit = (e) => {
    e.preventDefault()
    sessionStorage.setItem("unidade", unidade)
    navigate("/medico")
  }
  updateLead({
    UNIDADE: unidade.split("::")[0]
  })

  const handle_popup_submit = (e) => {
    e.preventDefault()
    disable_body_submit(false)
    setInputFilled(true)
    toggle_popup_unidade()
  }
  const { statusClubflex } = useContext(GlobalContext)
  const mainClassName = statusClubflex ? "page-clubflex" : ""

  return (
    <main className={`site-main page-unidade ${mainClassName}`}>
        {isEspecialidadeEspontanea && (
          <PopupEspecialidadeEspontanea close={setEspecialidadeEspontanea} />
        )}
      <Header back_to="/especialidade">
        <h1>Unidade</h1>
      </Header>
      <PageContent>
        <Timeline step={2} />
        <Popup ID="unidade" open={popup_unidade} handle={toggle_popup_unidade}>
          <form onSubmit={handle_popup_submit}>
            <div
              className="input-text input-search"
              style={{ marginBottom: "1.75rem" }}
            >
              <label htmlFor="search-unidade">
                <input
                  type="text"
                  name="search-unidade"
                  id="search-unidade"
                  onKeyUp={(e) => search_handle(e.target.value)}
                  placeholder="Buscar unidade"
                />
                <FiSearch />
              </label>
            </div>
            {htmlUnidades}
            {/* <div
              className={`input-submit ${
                is_popup_submit_disabled ? "disabled" : ""
              }`}
            >
              <button type="submit">Confirmar</button>
            </div> */}
          </form>
        </Popup>

        <form onSubmit={handle_submit}>
          <Link
            className={`selector-format ${isInputFilled ? "filled" : ""}`}
            onClick={(e) => open_popup(e)}
          >
            <span>
              {unidade.split("::")[0]}
              <FiEdit size={8} />
            </span>
            <FiChevronDown />
          </Link>

          <div
            className={`input-submit ${
              is_body_submit_disabled ? "disabled" : ""
            }`}
          >
            <button type="submit" disabled={is_body_submit_disabled}>
              Prosseguir
              <FiArrowRight />
            </button>
          </div>
        </form>
      </PageContent>
    </main>
  )
}
