const leadDefaultHeader = () => {
  var client = window.sessionStorage.getItem("client")

  return client
    ? {
        headers: {
          id: client
        }
      }
    : false
}

export default leadDefaultHeader
