import React, { useState } from "react"
import "./style.css"
import encryptClass from "../../../inc/Controllers/encrypt.class"
import api from "../../../services/api"

export default function NpsRating() {
  const [rating, setRating] = useState(0)
  const [feedback, setFeedback] = useState("")
  const [submitted, setSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleRatingClick = (selectedRating) => {
    setRating(selectedRating)
  }

  const handleSubmit = () => {
    
    if (rating === 0 || isSubmitting) {
      return
    }

    setIsSubmitting(true)

    const pacientFound = sessionStorage.getItem("patientFound")
    const data = JSON.parse(encryptClass.reveal(pacientFound))
    const {nome, email} = data

    api.post('/pesquisa', {
      nome,
      email,
      rating,
      feedback
    })
    .then(() => {
      setSubmitted(true)
      sessionStorage.clear()
    })
    .finally(() =>{
      setIsSubmitting(false)
    })
  }


  return (
    <div className="nps-rating">
      {submitted ? (
        <p className="tanks-feed">Obrigado pelo seu feedback!</p>
      ) : (
        <>
          <p>Como você avalia sua experiência?</p>
          <div className="stars">
            {[1, 2, 3, 4, 5].map((star) => (
              <span
                key={star}
                className={`star ${star <= rating ? "selected" : ""}`}
                onClick={() => handleRatingClick(star)}
              >
                ★
              </span>
            ))}
          </div>

          <textarea
            placeholder="Digite aqui suas sugestões ou críticas..."
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          />

          <button onClick={handleSubmit} disabled={rating === 0 || isSubmitting}>
            {isSubmitting ? "Enviando..." : "Enviar"}
          </button>
        </>
      )}
    </div>
  )
}
