import React, { useState } from "react"
import Navbar from "../Navbar"
import "./style.css"
import { Link } from "react-router-dom"
import siteLogo from "../../../assets/logo-segmedic.svg"
import Image from "../Image"
import { FiChevronLeft, FiChevronDown, FiHome } from "react-icons/fi"
import { GrClose } from "react-icons/gr"
import FollowUP from "../Followup"
import Hamburger from "hamburger-react"
import PopupMenuHeader from "./PopupMenu"

export default function Header({
  back_to,
  children,
  backToHome,
  hideFollowUP,
}) {
  const have_link = back_to !== undefined
  let header_class_name = children === undefined ? "no-children" : ""

  const [isMenuOpen, setMenuOpen] = useState(false)
  const [showPopup, setShowPopup] = useState(false)

  const togglePopup = () => {
    setShowPopup(!showPopup)
  }

  const [follow_up, follow_up_is_open] = useState(false)
  const open_follow_up = () => {
    follow_up_is_open(true)
  }
  const close_follow_up = () => {
    follow_up_is_open(false)
  }

  return (
    <header className={`site-header ${header_class_name}`}>
      <Navbar have_link={have_link}>
        {back_to ? (
          <Link to={back_to} className="back-to">
            {backToHome ? (
              <FiHome
                style={{ marginLeft: "-20px", transformOrigin: "left" }}
              />
            ) : (
              <div>
                <FiChevronLeft style={{ marginRight: "10px" }} />
                <span>Voltar</span>
              </div>

            )}
          </Link>
        ) : (
          false
        )}
        {!back_to &&(
          <div>
            <div className="header_logo_initial">
              <Image url={siteLogo} alt="Segmedic" className="site-logo" />
            </div>
          </div>
        )}
        <div className="header_hamburguer">

        <Hamburger
          toggled={isMenuOpen}
          toggle={setMenuOpen && setShowPopup}
          onClick={togglePopup}
          ></Hamburger>
        </div>
      </Navbar>
      {showPopup && <PopupMenuHeader onClose={() => setShowPopup(false)} />}
      <div className={`page-title ${follow_up ? "show-follow-up" : ""}`}>
        {!hideFollowUP ? (
          <>
            <FollowUP />
            <div className="title-follow-up">
              <Link to={"#"} onClick={open_follow_up}>
                <FiChevronDown />
              </Link>
              {children}
              <Link
                to={"#"}
                className="close-follow-up"
                onClick={close_follow_up}
              >
                <GrClose />
              </Link>
            </div>
          </>
        ) : (
          <>{children}</>
        )}
      </div>
    </header>
  )
}
