import React from "react";
import "./style.css";

export default function Navbar({children, have_link}) {

  const nav_class = (have_link) ? 'site-navigation space-between' : 'site-navigation';

  return (
    <nav className={nav_class}>
      {children}
    </nav>
  );

}