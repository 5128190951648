export default function toggleRef({ querySelector, text }) {
  var nodes = document.querySelectorAll(`div[${querySelector}]`)
  for (var i = 0; i < nodes.length; i++) {
    nodes[i].style.display = text !== "" ? "none" : "block"
  }

  for (const node of nodes) {
    const medico = node.getAttribute(querySelector).toLowerCase()
    
    if (medico.includes(text.toLowerCase())) {
      node.style.display = "block"
    }
  }
}
