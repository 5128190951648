const maskEmail = email => {
  // eslint-disable-next-line
  var maskedEmail = email.replace(/([^@\.])/g, "*").split("")
  var previous = ""

  for (var i = 0; i < maskedEmail.length; i++) {
    if (i <= 2 || previous === "." || previous === "@") {
      maskedEmail[i] = email[i]
    }
    previous = email[i]
  }

  return maskedEmail.join("")
}

export default maskEmail
