import React from "react";
import './style.css';

export default function Timeline({step}) {

  return(
    <div id="timeline" className={`step-${step}`}>
      <span>1</span>
      <span>2</span>
      <span>3</span>
      <span>4</span>
      <span>5</span>
    </div>
  )

}