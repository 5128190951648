import React, { useEffect, useState, useContext } from "react"
import Header from "../Global/Header"
import PageContent from "../Global/PageContent"
import { useNavigate, Link } from "react-router-dom"
import { FiArrowRight, FiChevronDown, FiEdit, FiCreditCard  } from "react-icons/fi"
import Popup from "../Global/Popup/"
import removerAcentos from "../../inc/Fx/removerAcentos"
import api from "../../services/api"
import updateLead from "../../inc/Fx/updateLead"
import getConvenioLogo from "../../inc/Fx/getConvenioLogo"
import "./style.css"
import { List } from "./List"
import { GlobalContext } from "../../contexts/global"
import Alert from "../Global/Alert"

export default function Convenio() {
  const [data, set_data] = useState([])
  const [convenio, set_convenio] = useState(null)
  const [plano, set_plano] = useState(null)
  const [carteirinha, set_carteirinha] = useState(false)
  const [convenio_particular, set_convenio_particular] = useState(false)
  const [popup_convenio, toggle_popup_convenio] = useState(false)
  const [popup_plano, toggle_popup_plano] = useState(false)
  const [is_popup_submit_disabled, disable_popup_submit] = useState(true)
  const [is_body_submit_disabled, disable_body_submit] = useState(true)
  const { setLoaderState } = useContext(GlobalContext)
  const [isOpen, setIsOpen] = useState(false)
  const [message, setMessage] = useState("")
  const [callback, setCallback] = useState(null)
  const navigate = useNavigate()
  const [isInputFilled, setInputFilled] = useState(false)

  const [htmlConvenios, setHtmlConvenios] = useState([])
  const [htmlPlanos, setHtmlPlanos] = useState([])
  const convenios = []

  const [convenioLogo, setConvenioLogo] = useState(null)
  const [planoLogo, setPlanoLogo] = useState(null)

  useEffect(() => {
    async function fetchInsurance() {
      await api
        .get("/insurance")
        .then((feegowResp) => {
          const { success, content } = feegowResp.data
          const contentData = []
          if (success) {
            Object.keys(content).forEach((index) => {
              let id = content[index].convenio_id
              let logo = getConvenioLogo(id)
              contentData.push({
                id: id,
                planos: content[index].planos,
                logo: logo,
              })
              if (getConvenioLogo(id) !== false) {
                convenios.push(
                  <div
                    className="content_main_convenio"
                    // className="input-radio-capsule"
                    style={{ marginBottom: "8px" }}
                    medico={removerAcentos(content[index].nome)}
                    key={id}
                  >
                    <label className="content_convenios">
                      <input
                        type="radio"
                        name="input-medico"
                        value={`${content[index].nome}::${id}`}
                        onChange={(e) => {
                          const selectedConvenio = e.target.value
                          const [convenioName, convenioId] = selectedConvenio.split("::")
                          set_convenio(e.target.value)
                          setConvenioLogo(getConvenioLogo(convenioId))
                          disable_popup_submit(false)
                          set_convenio_particular(false)
                          toggle_popup_convenio(false)
                          toggle_popup_plano(true)

                        }}
                      />
                      <div className="icon">
                        <span></span>
                      </div>
                      <div className="label">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "10px 0",
                          }}
                        >
                          <img
                            src={getConvenioLogo(id)}
                            alt={content[index].nome}
                            className="convenio-logo"
                          />
                          {content[index].nome}
                        </div>
                      </div>
                    </label>
                  </div>
                )
              }
            })
            set_data(contentData)
            setHtmlConvenios(convenios)
          }
        })
        .catch((error) => {})
    }
    fetchInsurance()
  }, [])

  useEffect(() => {
    set_plano(undefined)
    if (data.length == 0 || convenio === undefined) return

    const convenioId =  convenio ? parseInt(convenio.split("::")[1]) : undefined
    const { planos, logo } = data.find((item) => item.id == convenioId)
    const html = planos.map((plano) => {
      return (
        <div
          className="content_main_convenio"
          style={{ marginBottom: "8px" }}
          key={plano.plano_id}
          medico={removerAcentos(plano.plano)}
        >
          <label className="content_convenios">
            <input
              type="radio"
              name="input-medico"
              value={`${plano.plano}::${plano.plano_id}`}
              onChange={(e) => {
                set_plano(e.target.value)
                setPlanoLogo(convenioLogo)
                disable_popup_submit(false)
                toggle_popup_plano(false)

              }}
            />
            <div className="icon">
              <span></span>
            </div>
            <div className="label">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 0",
                }}
              >
                <img
                  src={logo}
                  alt='test'
                  className="convenio-logo"
                />
                {plano.plano}
              </div>
            </div>
          </label>
        </div>
      )
    })
    setHtmlPlanos(html)
  }, [convenio])

  const inputCarteirinha = () => {
    const handleCarteirinhaChange = (value) => {
      set_carteirinha(value);
    };

    return (
      plano && convenio ? (
        <div className="input-text input-card input_carteirinha" style={{ marginBottom: "1.75rem" }}>
          <label htmlFor="search-carteirinha">
            <input
              type="number"
              inputMode="numeric"
              name="search-carteirinha"
              id="search-carteirinha"
              onChange={(e) => handleCarteirinhaChange(e.target.value)}
              placeholder="Digite o número da carteirinha"
              pattern="\d"
              maxLength={20}
            />
            <FiCreditCard />
          </label>
        </div>
      ) : (
        null
      )
    );
  };

  const radioToggleConvenio = () => {
    return(
    <div className="input-radio">
    <label htmlFor="input-particular">
      <input
        type="radio"
        name="input-particular"
        id="input-particular"
        onChange={handle_change_particular}
        style={{ marginRight: "10px" }}
      />                
      <span
        style={{
          fontWeight: "700",
          color: "#2f4a5c",
        }}
      >
        Não possuo convênio
      </span>
    </label>
  </div>
  )}


  useEffect(() => {

    disable_body_submit(!(convenio && plano && carteirinha || convenio_particular))
  }, [convenio, plano, carteirinha, convenio_particular])

  function open_popup_convenio(e) {
    e.preventDefault()
    toggle_popup_convenio(true)
  }

  function open_popup_plano(e) {
    e.preventDefault()
    toggle_popup_plano(true)
  }

  const handle_submit = (e) => {
    e.preventDefault()
    if (convenio_particular) {
      sessionStorage.setItem("convenio", convenio_particular)
      sessionStorage.setItem("tabelaId", "2")
      navigate("/cadastro")
      updateLead({
        FEEGOW_CONVENIO: "PARTICULAR",
      })
    } else {
      sessionStorage.setItem("convenio", convenio)
      sessionStorage.setItem("plano", plano)

      const [name, insuranceId] = convenio.split("::")
      
      const elegibleRequest = {
        insuranceId: +insuranceId,
        patientCard: carteirinha,
      }

      // setLoaderState(true)
      updateLead({
        FEEGOW_CONVENIO: name,
        CARTEIRINHA_ID: carteirinha
      })

      return navigate("/cadastro")

      // api
      // .post("/elegible", elegibleRequest)
      //   .then((response) => {
      //     console.log(response.data.status)
      //
      //     if (response.data.status == false) {
      //       setMessage(
      //         "Não conseguimos localizar o número da sua carteirinha. Tente novamente ou escolha outra modalidade de agendamento."
      //       )
      //       setIsOpen(true)
      //       updateLead({
      //         FEEGOW_CONVENIO: name,
      //         CARTEIRINHA_ID: carteirinha,
      //         VALID_ID: response.data.id,
      //         VALID_RESPONSE_NURIA: 0
      //       })
      //       return
      //     }
      //
      //     updateLead({
      //       FEEGOW_CONVENIO: name,
      //       CARTEIRINHA_ID: carteirinha,
      //       VALID_ID: response.data.id,
      //       VALID_RESPONSE_NURIA: 1
      //     })
      //
      //
      //     navigate("/cadastro")
      //   })
      //   .catch((error) => {
      //     if (error.response.status < 500) {
      //       setMessage("Não conseguimos localizar o número da sua carteirinha. Tente novamente ou escolha outra modalidade de agendamento.")
      //       setIsOpen(true)
      //       updateLead({
      //         FEEGOW_CONVENIO: name,
      //         CARTEIRINHA_ID: carteirinha,
      //         VALID_RESPONSE_NURIA: 0
      //       })
      //
      //     } else {
      //       setMessage("Por favor, valide na recepção com sua carteirinha de saúde.")
      //       setIsOpen(true)
      //       updateLead({
      //         FEEGOW_CONVENIO: name,
      //         CARTEIRINHA_ID: carteirinha
      //       })
      //       setCallback("/cadastro")
      //     }
      //   })
      //   .finally(() => {
      //     setLoaderState(false)
      //   })
    }
  }

  const handle_popup_convenio_submit = (e) => {
    e.preventDefault()
    setInputFilled(true)
    set_convenio_particular(false)
    document.querySelector("#input-particular").checked = false
    toggle_popup_convenio()
  }

  const handle_popup_plano_submit = (e) => {
    e.preventDefault()
    toggle_popup_plano()
  }

  const handle_change_particular = () => {
    set_convenio_particular(true)
    setInputFilled(false)
    sessionStorage.removeItem("plano");
    set_convenio(undefined)
    set_plano(undefined)
  }

  return (
    <main className="site-main page-medico">
      <Header back_to="/" hideFollowUP={true}>
        <h1>Convênio</h1>
      </Header>
      <PageContent>
        <Alert className="box-alert" isOpen={isOpen} onClose={setIsOpen} title="Alerta" callback={callback}>
          <p>{message}</p>
        </Alert>

        <Popup
          ID="convenio"
          open={popup_convenio}
          handle={toggle_popup_convenio}
          >
          <List
            handle={handle_popup_convenio_submit}
            isDisabled={is_popup_submit_disabled}
            title="Convênio:"
          >
            {htmlConvenios}
          </List>
        </Popup>

        <Popup 
          ID="plano" 
          open={popup_plano} 
          handle={toggle_popup_plano}
          >
          <List
            handle={handle_popup_plano_submit}
            isDisabled={is_popup_submit_disabled}
            title="Plano:"
          >
            {htmlPlanos}
          </List>
        </Popup>

        <form onSubmit={handle_submit}>
          <Link
            className={`selector-format ${isInputFilled ? "filled" : ""}`}
            onClick={(e) => open_popup_convenio(e)}
          >
            <span>
              {convenio ? (
                <>
                  <img src={convenioLogo} alt="logo do convenio" className="convenio-logo" />
                  <div>
                    {convenio.split("::")[0]}
                  </div>
                </>
              ) : "Selecione o seu convênio"}
              <FiEdit size={8} />
            </span>
            <FiChevronDown />
          </Link>
          <Link
            style={{ marginTop: "8px" }}
            className={`selector-format ${isInputFilled ? "filled" : ""}`}
            onClick={(e) => open_popup_plano(e)}
          >
            <span>
              {plano ? (
                <>
                  <img src={planoLogo} alt="logo do plano" className="convenio-logo" />
                  <div>
                    {plano.split("::")[0]}
                  </div>
                </>
              )  : "Selecione o seu plano"}
              <FiEdit size={8} />
            </span>
            <FiChevronDown />
          </Link>
          <hr style={{ margin: "3rem 0", backgroundColor: "#8eb3be", border: 0, height: 1}}/>
          {inputCarteirinha()}
          {radioToggleConvenio()}
          <div
            className={`input-submit ${
              is_body_submit_disabled ? "disabled" : ""
            }`}
          >
            <button type="submit">
              Continuar
              <FiArrowRight />
            </button>
          </div>
        </form>
      </PageContent>
    </main>
  )
}
