import React from "react"
import "./style.css"

export default function Preco(preco) {
  return (
    <div className="content-preco">
      <h3>Sua consulta ficou em:</h3>
      <h2>
        {preco.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}
      </h2>
      <h5>
        você será cobrado somente no dia da consulta.
      </h5>
      <p className="alert">
        *Informamos que caso não compareça ao atendimento, sem aviso prévio de 24 horas, poderá ser cobrada uma multa equivalente ao valor do procedimento.
      </p>
      {/* <div className="clubflex-cta">
        <p className="align-center">Tenha desconto nas próximas consultas</p>
        <a href="#">Assinar o Club Flex</a>
      </div> */}
    </div>
  )
}
