import React from "react";
import "./style.css";
import { RiArrowGoBackLine, RiHome5Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const PopupEspecialidadeEspontanea = ({close}) => {
    const navigate = useNavigate()

    const handle_close = () =>{
        close(false)
    }
    const handle_initial = () =>{
        navigate("/")
    }

    return (
        <div className="pop_contain">
            <h2 className="title_pop_espontaneo">
                Especialidade por Ordem de Chegada
            </h2>
            <p className="text_pop">Nesta unidade, este serviço opera em ordem de chegada. Agradecemos sua compreensão.</p>
            <div className="content_submit">
                <p>Deseja escolher outra unidade para atendimento?</p>
                <div className="button_group">
                    <button onClick={handle_close} className="button_yes"><RiArrowGoBackLine />Voltar</button>
                    <button onClick={handle_initial} className="button_back"><RiHome5Fill />Voltar ao Início</button>
                </div>
            </div>
        </div>
    );
};

export default PopupEspecialidadeEspontanea;
