import React from "react"
import { Link } from "react-router-dom"
import { FiX } from "react-icons/fi"
import "./style.css"

const Popup = ({ open, ID, children, handle }) => {
  const close_popup = e => {
    e.preventDefault()
    handle(false)
  }

  return (
    <section className={`popup ${open ? "open" : ""}`} id={ID}>
      <div className="glass" onClick={close_popup}></div>
      <div className="content">
        <div className="content-head">
          <Link onClick={close_popup}>
            <FiX size={26} />
          </Link>
        </div>
        <div className="content-body">{children}</div>
      </div>
    </section>
  )
}

export default Popup
