import React, {useState} from "react"
import "./style.css"
import logo from "../../../../assets/segmedic-logo-white.svg"
import Image from "../../Image"
import Hamburger from "hamburger-react"


const PopupMenuHeader = ({ onClose }) => {
    const [isMenuOpen, setMenuOpen] = useState(true)

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen)
        onClose()
    }
    const handleSiteSegmedic = () =>{
        window.location.replace("https://www.segmedic.com.br/")
    }
    const handleAreaPaciente = () => {
        window.location.replace("https://app.segmedic.com.br/")
    }
    const handleBlog = () => {
        window.location.replace("https://www.segmedic.com.br/blog/")
    }
    const handleTermos = () => {
        window.location.replace("https://www.segmedic.com.br/etica-e-compliance/")
    }
    const handleAjuda = () =>{
        window.location.replace("https://www.segmedic.com.br/central-de-ajuda/")
    }

    return (
        <div className={`pop_contain_menu ${!isMenuOpen ? 'popup-enter' : 'popup-enter-actve'}`}>
            <div className="header_popup">
                <Image url={logo} alt="Segmedic" className="segmedic-logo-white"/>
                <Hamburger 
                    className="menu_popup"
                    toggled={isMenuOpen} 
                    toggle={toggleMenu}
                    onClick={toggleMenu}
                />

            </div>
            <div className="list_menu">
                <ul>
                    <li onClick={handleSiteSegmedic}>Ir para o Site</li>
                    <li onClick={handleAreaPaciente}>Área do Paciente</li>
                    <li onClick={handleBlog}>Blog</li>
                    <li onClick={handleTermos}>Termos e Compliance</li>
                    <li onClick={handleAjuda}>Ajuda</li>
                </ul>
            </div>
        </div>
    )
}

export default PopupMenuHeader
