import React from "react"
import SiteRoutes from "./routes"
import "./pages/Global/reset.css"
import "./pages/Global/globals.css"
import "./pages/Global/clubflex.css"

function App() {
  return <SiteRoutes />
}

export default App
